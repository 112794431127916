import { useTranslation } from "@dzangolab/react-i18n";
import { DropdownMenu, MenuItem } from "@dzangolab/react-ui";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./index.css";

import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetWorkspacesQuery } from "../../redux/apis/workspaces";
import { resetSelectedWorkspaces } from "../../redux/SelectedWorkspacesSlice";

import type { Workspace } from "core";

const WorkspaceSwitcher = () => {
  const { t } = useTranslation("workspaces");

  const [fetchWorkspaces, { data: workspaces }] = useLazyGetWorkspacesQuery();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onMenuItemClick = (workspace: Workspace) => {
    dispatch(resetSelectedWorkspaces());
    navigate(`/workspaces/${workspace.secondaryIdentifier}/releases`);
  };

  const workspacesMenuItems: MenuItem[] = workspaces
    ? workspaces.map((workspace: Workspace) => ({
        className: currentWorkspace?.id === workspace.id ? "active" : "",
        label: workspace.name,
        onClick: () => onMenuItemClick(workspace),
      }))
    : [];

  const menuItems: MenuItem[] = [
    ...workspacesMenuItems,
    {
      className: "my-workspaces-menu-item",
      label: t("myWorkspacesMenuItemLabel"),
      onClick: () => navigate(`/workspaces`),
    },
  ];

  useEffect(() => {
    fetchWorkspaces({ sort: [{ direction: "ASC", key: "name" }] });
  }, [fetchWorkspaces]);

  return (
    <div className="workspace-switcher">
      <div className="label-wrapper">
        <p>{currentWorkspace?.name}</p>
      </div>
      <DropdownMenu
        className="workspace-switcher-menu"
        hideDropdownIcon
        menu={menuItems}
        position="top-end"
        trigger={
          <div className="trigger-wrapper">
            <i className="pi pi-chevron-down" />
            <i className="pi pi-chevron-up" />
          </div>
        }
      />
    </div>
  );
};

export default WorkspaceSwitcher;
