import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import {
  BezierEdge,
  EdgeLabelRenderer,
  EdgeProps,
  Handle,
  Position,
  ReactFlow,
  getBezierPath,
} from "@xyflow/react";
import {
  ReleaseApp,
  SERVICE_STATUS,
  Service,
  WORKSPACE_RELEASE_STATUS,
  getKeyFromValue,
} from "core";
import { useState } from "react";
import { toast } from "react-toastify";

import { buildGraphNodesAndEdges, AppsService } from "./utils";
import { AppCard } from "../../../components";
import { STATUS_TAG_COLOR, USER_ROLES } from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../../libs";
import { useUnlinkServiceMutation } from "../../../redux/apis/release";

import "@xyflow/react/dist/style.css";
import "./graph.css";

interface Properties {
  onAddReleaseModule: (type: string, app?: ReleaseApp) => void;
}

const getReleaseTagColor = (status?: string | null) => {
  if (
    status === WORKSPACE_RELEASE_STATUS.STOPPED ||
    status === WORKSPACE_RELEASE_STATUS.STOPPING
  )
    return STATUS_TAG_COLOR.STOPPED;
  if (status === WORKSPACE_RELEASE_STATUS.ERROR) return STATUS_TAG_COLOR.ERROR;

  return STATUS_TAG_COLOR.RUNNING;
};

const renderServiceCard = ({
  data,
}: {
  data: {
    service: Service;
    appsService: AppsService[];
  };
}) => {
  if (data.service) {
    return (
      <div className="node-wrapper">
        {data.appsService?.length > 0 && (
          <Handle isConnectable={false} type="target" position={Position.Top} />
        )}
        <AppCard
          name={data.service.name ?? ""}
          key={`app-${data.service.secondaryIdentifier}`}
          url={data.service.publicUrl}
          status={{
            title: data.service.statusId
              ? getKeyFromValue(SERVICE_STATUS, data.service.statusId)
              : "",
            color: getReleaseTagColor(
              getKeyFromValue(SERVICE_STATUS, data.service.statusId),
            ),
          }}
        />
      </div>
    );
  }

  return null;
};

const renderGroupWrapper = ({ data }: { data: Service }) => {
  return <div className="group-wrapper"></div>;
};

const ReleaseGraph: React.FC<Properties> = (properties) => {
  const { onAddReleaseModule } = properties;
  const { t } = useTranslation("appOverview");
  const { user } = useUser();
  const [selectedEdge, setSelectedEdge] = useState<EdgeProps>();
  const { refetchWorkspaceAndRelease } = useCurrentWorkspace();
  const [unlinkService] = useUnlinkServiceMutation();

  const { release } = useCurrentWorkspace();

  if (!release) return <></>;

  const nodesEdges = buildGraphNodesAndEdges({
    t: t,
    release: release,
  });

  const initialNodes = [...nodesEdges.nodes];

  const initialEdges = [...nodesEdges.edges];

  const onAccept = async () => {
    if (!selectedEdge?.data) return;

    const { releaseAppId, workspaceServiceId } = selectedEdge.data as {
      releaseAppId: number;
      workspaceServiceId: number;
    };

    const response = await unlinkService({
      releaseAppId,
      releaseId: release.id,
      workspaceServiceId,
    });

    if ("error" in response) {
      toast.error(t("release-service.unlink.message.error"));
    } else {
      toast.success(t("release-service.unlink.message.success"));

      refetchWorkspaceAndRelease();
    }
  };

  const Header = ({ data }: { data: { label: string; type: string } }) => {
    return (
      <div className="node-header">
        <h2 className="module-text">{data.label}</h2>

        {hasRole(user, USER_ROLES.DEVELOPER) && (
          <Button
            iconLeft={"pi pi-plus"}
            variant="textOnly"
            onClick={() => onAddReleaseModule(data.type)}
          />
        )}
      </div>
    );
  };

  const renderAppCard = ({
    data,
  }: {
    data: {
      app: ReleaseApp;
      releaseApps?: ReleaseApp[];
      releaseApis?: ReleaseApp[];
      appsService?: AppsService[];
    };
  }) => {
    const { app, releaseApps, releaseApis, appsService } = data;

    if (!app) return null;

    const onSettingsOpen = () => {
      onAddReleaseModule(app.type, app);
    };

    const showTopHandle = app.type === "apis" && releaseApps?.length! > 0;
    const showBottomHandle =
      (app.type === "apps" && releaseApis?.length! > 0) ||
      appsService?.length! > 0;

    return (
      <div className="node-wrapper">
        {showTopHandle && (
          <Handle isConnectable={false} type="target" position={Position.Top} />
        )}
        <AppCard
          name={app.name ?? ""}
          subTitle={
            hasRole(user, USER_ROLES.DEVELOPER) && "latestCommitHash" in app
              ? app.latestCommitHash?.slice(0, 8) || ("" as string)
              : ""
          }
          key={`app-${app.slug}`}
          url={app.url}
          status={{
            title: app.status,
            color: getReleaseTagColor(app.status),
          }}
          type={app.type}
          onSettingsOpen={
            hasRole(user, USER_ROLES.DEVELOPER) ? onSettingsOpen : undefined
          }
        />
        {showBottomHandle && (
          <Handle
            isConnectable={false}
            type="source"
            position={Position.Bottom}
          />
        )}
      </div>
    );
  };

  const ServiceEdge = (properties: EdgeProps) => {
    const {
      sourcePosition,
      sourceX,
      sourceY,
      targetPosition,
      targetX,
      targetY,
    } = properties;

    const [, labelX, labelY] = getBezierPath({
      sourcePosition,
      sourceX,
      sourceY,
      targetPosition,
      targetX,
      targetY,
    });

    return (
      <>
        <BezierEdge {...properties} />
        {hasRole(user, USER_ROLES.DEVELOPER) && (
          <EdgeLabelRenderer>
            <Button
              iconLeft="pi pi-times-circle"
              variant="textOnly"
              style={{
                pointerEvents: "all",
                position: "absolute",
                transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
                zIndex: 100,
              }}
              onClick={() => setSelectedEdge(properties)}
            />
          </EdgeLabelRenderer>
        )}
      </>
    );
  };

  const nodeTypes = {
    appCard: renderAppCard,
    header: Header,
    serviceCard: renderServiceCard,
    groupWrapper: renderGroupWrapper,
  };

  const edgeTypes = {
    serviceEdge: ServiceEdge,
  };

  return (
    <>
      <ReactFlow
        nodes={initialNodes}
        nodesDraggable={false}
        edgesFocusable={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        edges={initialEdges}
        zoomOnDoubleClick={false}
        panOnDrag={false}
        zoomOnScroll={false}
        preventScrolling={false}
      />
      <ConfirmationModal
        accept={onAccept}
        header={t("release-service.unlink.confirmation.header")}
        message={t("release-service.unlink.confirmation.message", {
          serviceName: selectedEdge?.data?.workspaceServiceName,
        })}
        acceptButtonOptions={{
          label: t("release-service.unlink.confirmation.actions.unlink"),
        }}
        cancelButtonOptions={{
          label: t("release-service.unlink.confirmation.actions.cancel"),
        }}
        visible={!!selectedEdge}
        onHide={() => setSelectedEdge(undefined)}
      />
    </>
  );
};

export default ReleaseGraph;
