import { z as r } from "zod";
const tt = {
  READY: 1,
  RUNNING: 2,
  STOPPED: 3,
  BUILDING: 4,
  ERROR: 5,
  STARTING: 6,
  STOPPING: 7,
  DELETING: 8,
  UPDATING: 9,
  DELETED: 10
}, nt = {
  DATABASE: 1,
  EMAIL: 2,
  REDIS: 3,
  OTHER: 4
}, rt = "apis", q = {
  AWS_ECR: "aws_ecr",
  DOCKERHUB: "dockerhub"
};
var R = /* @__PURE__ */ ((e) => (e.BUILDING = "building", e.ERROR = "error", e.LOADING = "loading", e.READY = "ready", e.RUNNING = "running", e.STARTING = "starting", e.STOPPED = "stopped", e.STOPPING = "stopping", e))(R || {});
const at = r.object({
  id: r.number(),
  repositoryId: r.number().nullable(),
  releaseTypeId: r.number(),
  ruleTypeId: r.number(),
  includePatterns: r.array(r.string()).nullable(),
  excludePatterns: r.array(r.string()).nullable(),
  isActive: r.boolean(),
  isDefault: r.boolean(),
  createdAt: r.number(),
  updatedAt: r.number()
}), ot = r.object({
  id: r.number(),
  name: r.string(),
  slug: r.string(),
  path: r.string(),
  workspaceId: r.number().nullable(),
  createdAt: r.number(),
  updatedAt: r.number(),
  template: r.string().nullable()
}), it = r.object({
  name: r.string().max(255),
  content: r.string(),
  path: r.string()
}), st = r.object({
  workspaceId: r.number(),
  glossaryId: r.number()
}), ut = r.object({
  id: r.number(),
  name: r.string(),
  description: r.string(),
  locale: r.string()
}), ct = r.object({
  id: r.number(),
  term: r.string(),
  definition: r.string(),
  locale: r.string()
}), dt = r.object({
  id: r.number(),
  name: r.string(),
  localeCode: r.string(),
  nativeName: r.string(),
  isRightToLeftScript: r.boolean()
}), G = r.object({
  id: r.number(),
  envVars: r.any().nullable(),
  isPublic: r.boolean().nullable(),
  name: r.string(),
  port: r.number().nullable(),
  typeId: r.number(),
  publicUrl: r.string().nullable(),
  statusId: r.number().nullable(),
  image: r.string().nullable(),
  imageVersion: r.string().nullable(),
  volumeMounts: r.any().nullable(),
  scripts: r.any().nullable(),
  workspaceId: r.number(),
  connectionConfigs: r.any().nullable(),
  isExternal: r.boolean(),
  registryId: r.number().nullable(),
  deletedAt: r.number().nullable(),
  secondaryIdentifier: r.string(),
  createdAt: r.number(),
  updatedAt: r.number()
}), H = r.object({
  id: r.number(),
  branch: r.string(),
  buildScript: r.string().nullable(),
  description: r.string().nullable(),
  envVars: r.any().nullable(),
  isPublic: r.boolean().nullable(),
  name: r.string(),
  extensionConfig: r.any().nullable(),
  port: r.number().nullable(),
  proxyConfig: r.any().nullable(),
  releaseId: r.number(),
  repositoryId: r.number(),
  runScript: r.string().nullable(),
  slug: r.string(),
  sourcePath: r.string().nullable(),
  status: r.string().nullable(),
  type: r.string(),
  url: r.string().nullable(),
  registryId: r.number().nullable(),
  latestCommitHash: r.string().nullable(),
  deletedAt: r.number().nullable(),
  createdAt: r.number(),
  updatedAt: r.number()
}), Q = r.object({
  id: r.number(),
  name: r.string(),
  slug: r.string()
}), L = r.object({
  id: r.number(),
  name: r.string(),
  slug: r.string(),
  isSystemDefined: r.boolean(),
  description: r.string().nullable(),
  workspaceId: r.number()
}), V = r.object({
  id: r.number(),
  name: r.string(),
  description: r.string().nullable(),
  isMultitenant: r.boolean(),
  isDraft: r.boolean().optional(),
  type: L.nullable(),
  typeId: r.number().nullable(),
  workspaceId: r.number(),
  apps: r.array(H),
  services: r.array(G),
  state: Q.nullable(),
  appsServices: r.array(
    r.object({
      appId: r.number(),
      serviceId: r.number()
    })
  ).optional(),
  deletedAt: r.number().nullable(),
  closedAt: r.number().nullable(),
  secondaryIdentifier: r.string(),
  createdAt: r.number(),
  updatedAt: r.number()
}), lt = r.object({
  id: r.number(),
  releaseAppId: r.number(),
  workspaceServiceId: r.number(),
  createdAt: r.number(),
  updatedAt: r.number()
}), mt = r.object({
  name: r.string(),
  key: r.string(),
  versions: r.array(r.string()),
  packageManagers: r.array(r.string()),
  types: r.object({
    name: r.string(),
    key: r.string()
  }).array(),
  frameworks: r.object({
    name: r.string(),
    key: r.string(),
    versions: r.array(r.string())
  }).array()
}), X = r.object({
  awsAccessKeyId: r.string(),
  awsSecretAccessKey: r.string(),
  awsRegion: r.string(),
  authType: r.literal(q.AWS_ECR)
}), B = r.object({
  username: r.string(),
  password: r.string(),
  authType: r.literal("dockerhub")
}), _ = r.discriminatedUnion("authType", [
  X,
  B
]), ht = r.object({
  id: r.number(),
  authentication: _.nullable(),
  name: r.string().nullable(),
  provider: r.string(),
  url: r.string(),
  createdAt: r.number(),
  updatedAt: r.number()
}), ft = r.object({
  id: r.number(),
  branch: r.string().nullable(),
  branches: r.any().nullable(),
  credential: r.any().nullable(),
  name: r.string(),
  url: r.string(),
  webhookSecretToken: r.string().nullable(),
  workspaceId: r.number(),
  createdAt: r.number(),
  updatedAt: r.number()
}), gt = r.object({
  id: r.number(),
  // [BS 2024-APR-03] TODO: define type for config
  config: r.any().nullable(),
  description: r.string().nullable(),
  ownerId: r.string(),
  name: r.string(),
  isCodeGenerated: r.boolean().default(!1),
  latestRelease: V.nullable().optional(),
  serverId: r.number(),
  secondaryIdentifier: r.string(),
  deletedAt: r.number().nullable(),
  createdAt: r.number(),
  updatedAt: r.number()
}), bt = r.object({
  workspaceId: r.number(),
  userId: r.string(),
  dateFrom: r.number().nullable(),
  dateTo: r.number().nullable(),
  defaultReleaseRoleId: r.number().nullable()
}), yt = r.object({
  id: r.number(),
  host: r.string(),
  name: r.string(),
  ownerId: r.string(),
  port: r.string().nullable(),
  privateKey: r.string(),
  serverType: r.string(),
  traefikSetting: r.any(),
  username: r.string(),
  createdAt: r.number(),
  updatedAt: r.number()
}), wt = (e, t) => Object.entries(e).find(([, n]) => n === t)?.[0].toLowerCase(), pt = (e, t) => `${e}-services_${t}`, kt = (e) => e.startsWith("{") && e.endsWith("}") ? e.slice(1, -1) : e, Pt = (e) => {
  const t = Object.keys(e).sort(), n = {};
  for (const a of t)
    n[a] = e[a];
  return n;
}, Mt = (e) => {
  try {
    return new RegExp(e);
  } catch {
    return;
  }
}, J = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, $ = (e, t, n) => {
  let a;
  const o = J[e];
  return typeof o == "string" ? a = o : t === 1 ? a = o.one : a = o.other.replace("{{count}}", t.toString()), n?.addSuffix ? n.comparison && n.comparison > 0 ? "in " + a : a + " ago" : a;
};
function v(e) {
  return (t = {}) => {
    const n = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[n] || e.formats[e.defaultWidth];
  };
}
const U = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, z = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, K = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Z = {
  date: v({
    formats: U,
    defaultWidth: "full"
  }),
  time: v({
    formats: z,
    defaultWidth: "full"
  }),
  dateTime: v({
    formats: K,
    defaultWidth: "full"
  })
}, ee = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, te = (e, t, n, a) => ee[e];
function p(e) {
  return (t, n) => {
    const a = n?.context ? String(n.context) : "standalone";
    let o;
    if (a === "formatting" && e.formattingValues) {
      const s = e.defaultFormattingWidth || e.defaultWidth, c = n?.width ? String(n.width) : s;
      o = e.formattingValues[c] || e.formattingValues[s];
    } else {
      const s = e.defaultWidth, c = n?.width ? String(n.width) : e.defaultWidth;
      o = e.values[c] || e.values[s];
    }
    const i = e.argumentCallback ? e.argumentCallback(t) : t;
    return o[i];
  };
}
const ne = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, re = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, ae = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, oe = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, ie = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, se = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, ue = (e, t) => {
  const n = Number(e), a = n % 100;
  if (a > 20 || a < 10)
    switch (a % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, ce = {
  ordinalNumber: ue,
  era: p({
    values: ne,
    defaultWidth: "wide"
  }),
  quarter: p({
    values: re,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: p({
    values: ae,
    defaultWidth: "wide"
  }),
  day: p({
    values: oe,
    defaultWidth: "wide"
  }),
  dayPeriod: p({
    values: ie,
    defaultWidth: "wide",
    formattingValues: se,
    defaultFormattingWidth: "wide"
  })
};
function k(e) {
  return (t, n = {}) => {
    const a = n.width, o = a && e.matchPatterns[a] || e.matchPatterns[e.defaultMatchWidth], i = t.match(o);
    if (!i)
      return null;
    const s = i[0], c = a && e.parsePatterns[a] || e.parsePatterns[e.defaultParseWidth], m = Array.isArray(c) ? le(c, (d) => d.test(s)) : (
      // [TODO] -- I challenge you to fix the type
      de(c, (d) => d.test(s))
    );
    let f;
    f = e.valueCallback ? e.valueCallback(m) : m, f = n.valueCallback ? (
      // [TODO] -- I challenge you to fix the type
      n.valueCallback(f)
    ) : f;
    const l = t.slice(s.length);
    return { value: f, rest: l };
  };
}
function de(e, t) {
  for (const n in e)
    if (Object.prototype.hasOwnProperty.call(e, n) && t(e[n]))
      return n;
}
function le(e, t) {
  for (let n = 0; n < e.length; n++)
    if (t(e[n]))
      return n;
}
function me(e) {
  return (t, n = {}) => {
    const a = t.match(e.matchPattern);
    if (!a)
      return null;
    const o = a[0], i = t.match(e.parsePattern);
    if (!i)
      return null;
    let s = e.valueCallback ? e.valueCallback(i[0]) : i[0];
    s = n.valueCallback ? n.valueCallback(s) : s;
    const c = t.slice(o.length);
    return { value: s, rest: c };
  };
}
const he = /^(\d+)(th|st|nd|rd)?/i, fe = /\d+/i, ge = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, be = {
  any: [/^b/i, /^(a|c)/i]
}, ye = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, we = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, pe = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, ke = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Pe = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Me = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Oe = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, De = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, ve = {
  ordinalNumber: me({
    matchPattern: he,
    parsePattern: fe,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: k({
    matchPatterns: ge,
    defaultMatchWidth: "wide",
    parsePatterns: be,
    defaultParseWidth: "any"
  }),
  quarter: k({
    matchPatterns: ye,
    defaultMatchWidth: "wide",
    parsePatterns: we,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: k({
    matchPatterns: pe,
    defaultMatchWidth: "wide",
    parsePatterns: ke,
    defaultParseWidth: "any"
  }),
  day: k({
    matchPatterns: Pe,
    defaultMatchWidth: "wide",
    parsePatterns: Me,
    defaultParseWidth: "any"
  }),
  dayPeriod: k({
    matchPatterns: Oe,
    defaultMatchWidth: "any",
    parsePatterns: De,
    defaultParseWidth: "any"
  })
}, Se = {
  code: "en-US",
  formatDistance: $,
  formatLong: Z,
  formatRelative: te,
  localize: ce,
  match: ve,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
let xe = {};
function O() {
  return xe;
}
const N = 6048e5, Te = 864e5, S = Symbol.for("constructDateFrom");
function b(e, t) {
  return typeof e == "function" ? e(t) : e && typeof e == "object" && S in e ? e[S](t) : e instanceof Date ? new e.constructor(t) : new Date(t);
}
function h(e, t) {
  return b(t || e, e);
}
function x(e) {
  const t = h(e), n = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return n.setUTCFullYear(t.getFullYear()), +e - +n;
}
function We(e, ...t) {
  const n = b.bind(
    null,
    e || t.find((a) => typeof a == "object")
  );
  return t.map(n);
}
function T(e, t) {
  const n = h(e, t?.in);
  return n.setHours(0, 0, 0, 0), n;
}
function Ye(e, t, n) {
  const [a, o] = We(
    n?.in,
    e,
    t
  ), i = T(a), s = T(o), c = +i - x(i), m = +s - x(s);
  return Math.round((c - m) / Te);
}
function Ie(e, t) {
  const n = h(e, t?.in);
  return n.setFullYear(n.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
}
function Ee(e, t) {
  const n = h(e, t?.in);
  return Ye(n, Ie(n)) + 1;
}
function P(e, t) {
  const n = O(), a = t?.weekStartsOn ?? t?.locale?.options?.weekStartsOn ?? n.weekStartsOn ?? n.locale?.options?.weekStartsOn ?? 0, o = h(e, t?.in), i = o.getDay(), s = (i < a ? 7 : 0) + i - a;
  return o.setDate(o.getDate() - s), o.setHours(0, 0, 0, 0), o;
}
function M(e, t) {
  return P(e, { ...t, weekStartsOn: 1 });
}
function j(e, t) {
  const n = h(e, t?.in), a = n.getFullYear(), o = b(n, 0);
  o.setFullYear(a + 1, 0, 4), o.setHours(0, 0, 0, 0);
  const i = M(o), s = b(n, 0);
  s.setFullYear(a, 0, 4), s.setHours(0, 0, 0, 0);
  const c = M(s);
  return n.getTime() >= i.getTime() ? a + 1 : n.getTime() >= c.getTime() ? a : a - 1;
}
function Ne(e, t) {
  const n = j(e, t), a = b(t?.in || e, 0);
  return a.setFullYear(n, 0, 4), a.setHours(0, 0, 0, 0), M(a);
}
function je(e, t) {
  const n = h(e, t?.in), a = +M(n) - +Ne(n);
  return Math.round(a / N) + 1;
}
function C(e, t) {
  const n = h(e, t?.in), a = n.getFullYear(), o = O(), i = t?.firstWeekContainsDate ?? t?.locale?.options?.firstWeekContainsDate ?? o.firstWeekContainsDate ?? o.locale?.options?.firstWeekContainsDate ?? 1, s = b(t?.in || e, 0);
  s.setFullYear(a + 1, 0, i), s.setHours(0, 0, 0, 0);
  const c = P(s, t), m = b(t?.in || e, 0);
  m.setFullYear(a, 0, i), m.setHours(0, 0, 0, 0);
  const f = P(m, t);
  return +n >= +c ? a + 1 : +n >= +f ? a : a - 1;
}
function Ce(e, t) {
  const n = O(), a = t?.firstWeekContainsDate ?? t?.locale?.options?.firstWeekContainsDate ?? n.firstWeekContainsDate ?? n.locale?.options?.firstWeekContainsDate ?? 1, o = C(e, t), i = b(t?.in || e, 0);
  return i.setFullYear(o, 0, a), i.setHours(0, 0, 0, 0), P(i, t);
}
function Fe(e, t) {
  const n = h(e, t?.in), a = +P(n, t) - +Ce(n, t);
  return Math.round(a / N) + 1;
}
function u(e, t) {
  const n = e < 0 ? "-" : "", a = Math.abs(e).toString().padStart(t, "0");
  return n + a;
}
const g = {
  // Year
  y(e, t) {
    const n = e.getFullYear(), a = n > 0 ? n : 1 - n;
    return u(t === "yy" ? a % 100 : a, t.length);
  },
  // Month
  M(e, t) {
    const n = e.getMonth();
    return t === "M" ? String(n + 1) : u(n + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return u(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const n = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.toUpperCase();
      case "aaa":
        return n;
      case "aaaaa":
        return n[0];
      case "aaaa":
      default:
        return n === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return u(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return u(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return u(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return u(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const n = t.length, a = e.getMilliseconds(), o = Math.trunc(
      a * Math.pow(10, n - 3)
    );
    return u(o, t.length);
  }
}, w = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, W = {
  // Era
  G: function(e, t, n) {
    const a = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return n.era(a, { width: "abbreviated" });
      case "GGGGG":
        return n.era(a, { width: "narrow" });
      case "GGGG":
      default:
        return n.era(a, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, n) {
    if (t === "yo") {
      const a = e.getFullYear(), o = a > 0 ? a : 1 - a;
      return n.ordinalNumber(o, { unit: "year" });
    }
    return g.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, n, a) {
    const o = C(e, a), i = o > 0 ? o : 1 - o;
    if (t === "YY") {
      const s = i % 100;
      return u(s, 2);
    }
    return t === "Yo" ? n.ordinalNumber(i, { unit: "year" }) : u(i, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const n = j(e);
    return u(n, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const n = e.getFullYear();
    return u(n, t.length);
  },
  // Quarter
  Q: function(e, t, n) {
    const a = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(a);
      case "QQ":
        return u(a, 2);
      case "Qo":
        return n.ordinalNumber(a, { unit: "quarter" });
      case "QQQ":
        return n.quarter(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return n.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return n.quarter(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, n) {
    const a = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(a);
      case "qq":
        return u(a, 2);
      case "qo":
        return n.ordinalNumber(a, { unit: "quarter" });
      case "qqq":
        return n.quarter(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return n.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return n.quarter(a, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, n) {
    const a = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return g.M(e, t);
      case "Mo":
        return n.ordinalNumber(a + 1, { unit: "month" });
      case "MMM":
        return n.month(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return n.month(a, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return n.month(a, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, n) {
    const a = e.getMonth();
    switch (t) {
      case "L":
        return String(a + 1);
      case "LL":
        return u(a + 1, 2);
      case "Lo":
        return n.ordinalNumber(a + 1, { unit: "month" });
      case "LLL":
        return n.month(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return n.month(a, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return n.month(a, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, n, a) {
    const o = Fe(e, a);
    return t === "wo" ? n.ordinalNumber(o, { unit: "week" }) : u(o, t.length);
  },
  // ISO week of year
  I: function(e, t, n) {
    const a = je(e);
    return t === "Io" ? n.ordinalNumber(a, { unit: "week" }) : u(a, t.length);
  },
  // Day of the month
  d: function(e, t, n) {
    return t === "do" ? n.ordinalNumber(e.getDate(), { unit: "date" }) : g.d(e, t);
  },
  // Day of year
  D: function(e, t, n) {
    const a = Ee(e);
    return t === "Do" ? n.ordinalNumber(a, { unit: "dayOfYear" }) : u(a, t.length);
  },
  // Day of week
  E: function(e, t, n) {
    const a = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return n.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return n.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return n.day(a, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return n.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, n, a) {
    const o = e.getDay(), i = (o - a.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(i);
      case "ee":
        return u(i, 2);
      case "eo":
        return n.ordinalNumber(i, { unit: "day" });
      case "eee":
        return n.day(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return n.day(o, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return n.day(o, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return n.day(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, n, a) {
    const o = e.getDay(), i = (o - a.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(i);
      case "cc":
        return u(i, t.length);
      case "co":
        return n.ordinalNumber(i, { unit: "day" });
      case "ccc":
        return n.day(o, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return n.day(o, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return n.day(o, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return n.day(o, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, n) {
    const a = e.getDay(), o = a === 0 ? 7 : a;
    switch (t) {
      case "i":
        return String(o);
      case "ii":
        return u(o, t.length);
      case "io":
        return n.ordinalNumber(o, { unit: "day" });
      case "iii":
        return n.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return n.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return n.day(a, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return n.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, n) {
    const o = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, n) {
    const a = e.getHours();
    let o;
    switch (a === 12 ? o = w.noon : a === 0 ? o = w.midnight : o = a / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, n) {
    const a = e.getHours();
    let o;
    switch (a >= 17 ? o = w.evening : a >= 12 ? o = w.afternoon : a >= 4 ? o = w.morning : o = w.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, n) {
    if (t === "ho") {
      let a = e.getHours() % 12;
      return a === 0 && (a = 12), n.ordinalNumber(a, { unit: "hour" });
    }
    return g.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, n) {
    return t === "Ho" ? n.ordinalNumber(e.getHours(), { unit: "hour" }) : g.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, n) {
    const a = e.getHours() % 12;
    return t === "Ko" ? n.ordinalNumber(a, { unit: "hour" }) : u(a, t.length);
  },
  // Hour [1-24]
  k: function(e, t, n) {
    let a = e.getHours();
    return a === 0 && (a = 24), t === "ko" ? n.ordinalNumber(a, { unit: "hour" }) : u(a, t.length);
  },
  // Minute
  m: function(e, t, n) {
    return t === "mo" ? n.ordinalNumber(e.getMinutes(), { unit: "minute" }) : g.m(e, t);
  },
  // Second
  s: function(e, t, n) {
    return t === "so" ? n.ordinalNumber(e.getSeconds(), { unit: "second" }) : g.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return g.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, n) {
    const a = e.getTimezoneOffset();
    if (a === 0)
      return "Z";
    switch (t) {
      case "X":
        return I(a);
      case "XXXX":
      case "XX":
        return y(a);
      case "XXXXX":
      case "XXX":
      default:
        return y(a, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, n) {
    const a = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return I(a);
      case "xxxx":
      case "xx":
        return y(a);
      case "xxxxx":
      case "xxx":
      default:
        return y(a, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, n) {
    const a = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + Y(a, ":");
      case "OOOO":
      default:
        return "GMT" + y(a, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, n) {
    const a = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + Y(a, ":");
      case "zzzz":
      default:
        return "GMT" + y(a, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, n) {
    const a = Math.trunc(+e / 1e3);
    return u(a, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, n) {
    return u(+e, t.length);
  }
};
function Y(e, t = "") {
  const n = e > 0 ? "-" : "+", a = Math.abs(e), o = Math.trunc(a / 60), i = a % 60;
  return i === 0 ? n + String(o) : n + String(o) + t + u(i, 2);
}
function I(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + u(Math.abs(e) / 60, 2) : y(e, t);
}
function y(e, t = "") {
  const n = e > 0 ? "-" : "+", a = Math.abs(e), o = u(Math.trunc(a / 60), 2), i = u(a % 60, 2);
  return n + o + t + i;
}
const E = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, F = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, Ae = (e, t) => {
  const n = e.match(/(P+)(p+)?/) || [], a = n[1], o = n[2];
  if (!o)
    return E(e, t);
  let i;
  switch (a) {
    case "P":
      i = t.dateTime({ width: "short" });
      break;
    case "PP":
      i = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      i = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      i = t.dateTime({ width: "full" });
      break;
  }
  return i.replace("{{date}}", E(a, t)).replace("{{time}}", F(o, t));
}, qe = {
  p: F,
  P: Ae
}, Re = /^D+$/, Ge = /^Y+$/, He = ["D", "DD", "YY", "YYYY"];
function Qe(e) {
  return Re.test(e);
}
function Le(e) {
  return Ge.test(e);
}
function Ve(e, t, n) {
  const a = Xe(e, t, n);
  if (console.warn(a), He.includes(e))
    throw new RangeError(a);
}
function Xe(e, t, n) {
  const a = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${a} to the input \`${n}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
function Be(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function _e(e) {
  return !(!Be(e) && typeof e != "number" || isNaN(+h(e)));
}
const Je = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, $e = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Ue = /^'([^]*?)'?$/, ze = /''/g, Ke = /[a-zA-Z]/;
function A(e, t, n) {
  const a = O(), o = n?.locale ?? a.locale ?? Se, i = n?.firstWeekContainsDate ?? n?.locale?.options?.firstWeekContainsDate ?? a.firstWeekContainsDate ?? a.locale?.options?.firstWeekContainsDate ?? 1, s = n?.weekStartsOn ?? n?.locale?.options?.weekStartsOn ?? a.weekStartsOn ?? a.locale?.options?.weekStartsOn ?? 0, c = h(e, n?.in);
  if (!_e(c))
    throw new RangeError("Invalid time value");
  let m = t.match($e).map((l) => {
    const d = l[0];
    if (d === "p" || d === "P") {
      const D = qe[d];
      return D(l, o.formatLong);
    }
    return l;
  }).join("").match(Je).map((l) => {
    if (l === "''")
      return { isToken: !1, value: "'" };
    const d = l[0];
    if (d === "'")
      return { isToken: !1, value: Ze(l) };
    if (W[d])
      return { isToken: !0, value: l };
    if (d.match(Ke))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + d + "`"
      );
    return { isToken: !1, value: l };
  });
  o.localize.preprocessor && (m = o.localize.preprocessor(c, m));
  const f = {
    firstWeekContainsDate: i,
    weekStartsOn: s,
    locale: o
  };
  return m.map((l) => {
    if (!l.isToken)
      return l.value;
    const d = l.value;
    (!n?.useAdditionalWeekYearTokens && Le(d) || !n?.useAdditionalDayOfYearTokens && Qe(d)) && Ve(d, t, String(e));
    const D = W[d[0]];
    return D(c, d, o.localize, f);
  }).join("");
}
function Ze(e) {
  const t = e.match(Ue);
  return t ? t[1].replace(ze, "'") : e;
}
const Ot = (e) => e ? A(new Date(e), "dd MMM yyyy") : "-", Dt = (e) => A(/* @__PURE__ */ new Date(), e ?? "yyyy-MM-dd HH:mm:ss");
export {
  q as REGISTRY_PROVIDER,
  rt as RELEASE_APP_TYPE_API,
  tt as SERVICE_STATUS,
  nt as SERVICE_TYPES,
  R as WORKSPACE_RELEASE_STATUS,
  at as branchMatchingRuleSchema,
  it as documentSchema,
  ot as documentTypeSchema,
  Ot as formatDate,
  pt as generateServiceName,
  Dt as getCurrentTime,
  wt as getKeyFromValue,
  ct as glossaryEntryI18nSchema,
  ut as glossaryI18nSchema,
  Mt as parseRegexPattern,
  ht as registrySchema,
  mt as releaseAppEnginePresetSchema,
  H as releaseAppSchema,
  lt as releaseAppsServiceSchema,
  V as releaseSchema,
  Q as releaseStateSchema,
  L as releaseTypeScheme,
  ft as repositorySchema,
  kt as sanitizeVolumeMounts,
  yt as serverConfigSchema,
  G as serviceSchema,
  Pt as sortObject,
  dt as supportedLocalesSchema,
  st as workspaceGlossariesSchema,
  gt as workspaceSchema,
  bt as workspaceUserSchema
};
