import { Card, CardBody, CardHeader, Tag } from "@dzangolab/react-ui";
import React from "react";

interface AppCardProperties {
  name: string;
  url?: string | null;
  status?: {
    title?: string | null;
    color?: string;
  };
  type?: string;
  subTitle?: string;
  onSettingsOpen?: () => void;
}

const AppCard: React.FC<AppCardProperties> = (properties) => {
  const { name, status, subTitle, url, onSettingsOpen, type } = properties;

  return (
    <>
      <Card className="app-card">
        <CardHeader>
          <div className="header-title-container">
            <h3 className="app-card-title ellipsis-card-title">
              {name}
              {subTitle && (
                <span className="app-card-subtitle"> ({subTitle}) </span>
              )}
            </h3>
            {status && status.title && (
              <Tag color={status?.color} label={status?.title}></Tag>
            )}
          </div>

          {onSettingsOpen && (
            <button
              className="app-card-settings-button"
              onClick={onSettingsOpen}
            >
              <i className="pi pi-cog" style={{ marginTop: 5 }}></i>
            </button>
          )}
        </CardHeader>

        {url && status?.title === "running" && (
          <CardBody>
            <a
              className="app-external-link"
              href={url}
              target="_blank"
              rel="noreferrer"
            >
              <i className="pi pi-external-link"></i>
            </a>
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default AppCard;
