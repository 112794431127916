import { useTranslation } from "@dzangolab/react-i18n";
import { Button, LoadingIcon } from "@dzangolab/react-ui";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Actions as ReleaseAction } from "../../../components";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import {
  useLazyDeployReleaseQuery,
  useLazyStopReleaseQuery,
  useMarkReleaseAsReadyMutation,
} from "../../../redux/apis/release";
import { useLazyGenerateSourceCodeQuery } from "../../../redux/apis/workspaces";
import { setWorkspaceAndRelease } from "../../../redux/SelectedWorkspacesSlice";

interface Properties {
  isDraft?: boolean;
}

const Toolbar: React.FC<Properties> = ({ isDraft }) => {
  const { t } = useTranslation("release");
  const dispatch = useDispatch();

  const {
    getReleaseActionStates,
    release: currentRelease,
    workspace: currentWorkspace,
    refetchWorkspaceAndRelease,
  } = useCurrentWorkspace();

  const [markReleaseAsReady, { isLoading: isUpdatingReleaseDraftStatus }] =
    useMarkReleaseAsReadyMutation();
  const [generateWorkspace, { isFetching: isLoadingGenerateAction }] =
    useLazyGenerateSourceCodeQuery();

  const { disablePlay, disableStop, isProcessing, isStarting } =
    getReleaseActionStates();
  const [deployRelease, { isFetching: isLoadingDeployRelease }] =
    useLazyDeployReleaseQuery();
  const [stopRelease, { isFetching: isLoadingStopRelease }] =
    useLazyStopReleaseQuery();

  const generateWorkspaceCode = async () => {
    if (currentWorkspace && currentRelease) {
      try {
        const response = await generateWorkspace({
          workspaceId: currentWorkspace.id,
          releaseId: currentRelease.id,
        });

        if (response.error) {
          const error = (response.error as any).data as Record<string, string>;

          toast.error(error.message || t("messages.error.generate"));

          return;
        }

        dispatch(
          setWorkspaceAndRelease({
            workspaceSecondaryId: currentWorkspace.secondaryIdentifier,
            workspace: response.data,
            release: response.data?.latestRelease,
          }),
        );

        toast.success(t("messages.success.generate"));
      } catch (error: any) {
        toast.error(error.message || t("messages.error.generate"));
      }
    }
  };

  const onMarkAsReady = async () => {
    if (currentRelease && currentWorkspace) {
      const response = await markReleaseAsReady({
        releaseId: currentRelease.id,
        workspaceId: currentWorkspace.id,
      });

      if (response.error) {
        toast.error(t("mark-as-ready.message.error"));

        return;
      }

      refetchWorkspaceAndRelease();

      toast.success(t("mark-as-ready.message.success"));
    }
  };

  const stopActionHandler = async () => {
    if (currentWorkspace && currentRelease) {
      const response = await stopRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: currentRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }

      refetchWorkspaceAndRelease();

      toast.info(t("messages.info.stopping-release"));
    }
  };

  const shareActionHandler = () => {
    navigator.clipboard.writeText(window.location.href);

    toast.success(t("messages.share"));
  };

  const playActionHandler = async () => {
    if (currentWorkspace && currentRelease) {
      const response = await deployRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: currentRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }

      refetchWorkspaceAndRelease();
    }
  };

  const isLoadingAction = isLoadingDeployRelease || isLoadingStopRelease;

  if (currentRelease?.closedAt) {
    return null;
  }

  return (
    <>
      {currentWorkspace?.isCodeGenerated ? (
        isDraft ? (
          <Button
            label={t("mark-as-ready.button.label")}
            variant="filled"
            size="small"
            className="mark-as-ready"
            loading={isUpdatingReleaseDraftStatus}
            onClick={onMarkAsReady}
            iconRight={isUpdatingReleaseDraftStatus && <LoadingIcon />}
          />
        ) : (
          <ReleaseAction
            disablePlay={
              !currentWorkspace?.isCodeGenerated ||
              isLoadingAction ||
              isProcessing ||
              isStarting ||
              disablePlay
            }
            disableStop={
              !currentWorkspace?.isCodeGenerated ||
              isLoadingAction ||
              isProcessing ||
              isStarting ||
              disableStop
            }
            hideShareMenu
            isStarting={isStarting || isLoadingDeployRelease}
            isStopping={isProcessing || isLoadingStopRelease}
            onPlay={playActionHandler}
            onStop={stopActionHandler}
            onShare={shareActionHandler}
          />
        )
      ) : (
        <Button
          label={t("generate-button.label")}
          variant="filled"
          size="small"
          disabled={currentWorkspace?.isCodeGenerated}
          onClick={generateWorkspaceCode}
          className="generate-code"
          loading={isLoadingGenerateAction}
          iconRight={isLoadingGenerateAction && <LoadingIcon />}
        />
      )}
    </>
  );
};

export default Toolbar;
