import { Release, ReleaseApp, WORKSPACE_RELEASE_STATUS } from "core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useLazyGetReleaseBySecondaryIdentifierQuery } from "../redux/apis/release";
import { useLazyGetWorkspaceBySecondaryIdentifierQuery } from "../redux/apis/workspaces";
import { setWorkspaceAndRelease } from "../redux/SelectedWorkspacesSlice";
import { RootState } from "../redux/store";

export const useCurrentWorkspace = () => {
  const parameters = useParams();
  const { workspaceSecondaryId, releaseSecondaryId } = parameters;
  const [fetchWorkspaceBySecondaryId] =
    useLazyGetWorkspaceBySecondaryIdentifierQuery();
  const [fetchReleaseBySecondaryId] =
    useLazyGetReleaseBySecondaryIdentifierQuery();
  const [currentWorkspace, setCurrentWorkspace] = useState<any>();
  const [currentRelease, setCurrentRelease] = useState<any>();

  const workspaces = useSelector(
    (state: RootState) => state.selectedWorkspaces.workspaces,
  );

  useEffect(() => {
    if (workspaces && workspaceSecondaryId) {
      const activeWorkspace = workspaces[workspaceSecondaryId];

      if (activeWorkspace) {
        setCurrentWorkspace(activeWorkspace.workspace);
        setCurrentRelease(activeWorkspace.selectedRelease);
      }
    }
  }, [releaseSecondaryId, workspaceSecondaryId, workspaces]);

  const refetchWorkspaceAndRelease = async () => {
    if (workspaceSecondaryId) {
      const releaseSecondaryId =
        parameters.secondaryIdentifier ??
        workspaces[workspaceSecondaryId]?.selectedRelease?.secondaryIdentifier;

      if (currentWorkspace && releaseSecondaryId) {
        const response = await Promise.all([
          fetchWorkspaceBySecondaryId(workspaceSecondaryId),
          fetchReleaseBySecondaryId({
            workspaceId: currentWorkspace.id,
            releaseSecondaryId,
          }),
        ]);

        if ("error" in response[0] || "error" in response[1]) {
          return;
        }

        setWorkspaceAndRelease({
          workspaceSecondaryId: currentWorkspace.secondaryIdentifier,
          workspace: response[0].data,
          release: response[1].data,
        });

        setCurrentWorkspace(response[0].data);
        setCurrentRelease(response[1].data);
      }
    }
  };

  const getReleaseActionStates = (release?: Release) => {
    const apps = release ? release.apps : (currentRelease?.apps ?? []);

    // Determine the overall status based on the app statuses
    const allBuilding = apps.every(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.BUILDING,
    );
    const allRunning = apps.every(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.RUNNING,
    );
    const anyReady = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.READY,
    );
    const anyStop = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.STOPPED,
    );
    const anyError = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.ERROR,
    );

    // Check if any app status is null or an empty string
    const anyInvalidStatus = apps.some((app: ReleaseApp) => !app.status);

    const disablePlay = allBuilding || allRunning;
    const disableStop =
      allBuilding || anyReady || anyError || anyStop || anyInvalidStatus;

    const isProcessing = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.STOPPING,
    );

    const isStarting = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.STARTING,
    );

    return { disablePlay, disableStop, isProcessing, isStarting };
  };

  return {
    workspace: currentWorkspace,
    release: currentRelease,
    getReleaseActionStates,
    refetchWorkspaceAndRelease,
  };
};
