import { useTranslation } from "@dzangolab/react-i18n";

import BaseWorkspaceForm from "../../../../components/Form/WorkspaceForm";
import SectionContainer from "../../../../components/SectionContainer";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";

const SettingsGeneral = () => {
  const { t } = useTranslation("settings");
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  return (
    <SectionContainer
      title={t("generalSettings.sections.edit.title")}
      subTitle={t("generalSettings.sections.edit.subTitle")}
    >
      <BaseWorkspaceForm workspace={currentWorkspace} />
    </SectionContainer>
  );
};

export default SettingsGeneral;
