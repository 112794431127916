import { useTranslation } from "@dzangolab/react-i18n";
import { TabView } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";

import AddReleaseApp from "./AddReleaseApp";
import ReleaseGraph from "./ReleaseGraph";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";

interface IProperties {
  addReleaseModuleHandler: (type: string, app?: ReleaseApp) => void;
}

const OverviewTab: React.FC<IProperties> = (properties) => {
  const { t } = useTranslation("app");
  const { addReleaseModuleHandler } = properties;
  const [visibleTabs, setVisibleTabs] = useState<string[]>();
  const [activeTab, setActiveTab] = useState<string>();
  const { release: currentRelease } = useCurrentWorkspace();

  useEffect(() => {
    if (!currentRelease) {
      return;
    }

    const persistedTabsUnparsed = window.localStorage.getItem(
      `overview-tab-${currentRelease.secondaryIdentifier}`,
    );

    const persistedTabs = persistedTabsUnparsed
      ? JSON.parse(persistedTabsUnparsed)
      : [];

    if (persistedTabs.length === 0) {
      setVisibleTabs(["overview"]);

      return;
    }

    if (persistedTabs.visibleTabs.length > 0) {
      setVisibleTabs(persistedTabs.visibleTabs);
    } else {
      setVisibleTabs(["overview"]);
    }
  }, [currentRelease]);

  useEffect(() => {
    if (!activeTab || !currentRelease) {
      return;
    }

    const validActiveTab = currentRelease.apps.some(
      (app: ReleaseApp) => app.slug === activeTab,
    );

    if (!validActiveTab) {
      setActiveTab("overview");
    }
  }, [activeTab, currentRelease]);

  if (!currentRelease) {
    return <></>;
  }

  const onReleaseAppSelected = (app: ReleaseApp) => {
    const isAppVisible = visibleTabs?.includes(app.slug);

    if (isAppVisible) {
      setActiveTab(app.slug);
    } else {
      setVisibleTabs([...(visibleTabs ?? []), app.slug]);
      setActiveTab(app.slug);
    }
  };

  const renderReleaseGraph = () => {
    return (
      <ReleaseGraph
        onAddReleaseModule={(type, app) => {
          addReleaseModuleHandler(type, app);

          if (!app) {
            return;
          }

          onReleaseAppSelected(app);
        }}
      />
    );
  };

  const renderAppModule = (app: ReleaseApp) => {
    return (
      <div className="add-release-app">
        <AddReleaseApp
          key={app.slug}
          releaseApp={app}
          onHide={() => {}}
          moduleType={app.type}
        />
      </div>
    );
  };

  const allTabs = [
    {
      label: t("header.menu.overview"),
      key: "overview",
      children: renderReleaseGraph(),
      closable: false,
    },
    ...(currentRelease.apps.map((app: ReleaseApp) => ({
      label: app.name,
      key: app.slug,
      children: renderAppModule(app),
      closable: true,
    })) ?? []),
  ];

  if (!visibleTabs || visibleTabs.length === 0) {
    return <></>;
  }

  return (
    <TabView
      visibleTabs={visibleTabs}
      tabs={allTabs}
      activeKey={activeTab ?? "overview"}
      id={`overview-tab-${currentRelease.secondaryIdentifier}`}
      onVisibleTabsChange={(keys) => {
        if (visibleTabs !== keys) {
          setVisibleTabs(keys);
          setActiveTab("overview");
        }
      }}
      onActiveTabChange={(key) => {
        setActiveTab(key);
      }}
    />
  );
};

export default OverviewTab;
