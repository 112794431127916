import {
  Select,
  Textarea,
  TextInput,
  useFormContext,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { ReleaseCreateInput, ReleaseType } from "core";

interface Properties {
  actionLabel?: string;
  fieldErrors?: Record<string, string>;
  handleCancel: () => void;
  isLoading: boolean;
  releaseTypes?: ReleaseType[];
}

const ReleaseFormFields: React.FC<Properties> = (properties) => {
  const { actionLabel, handleCancel, isLoading, releaseTypes } = properties;
  const { t } = useTranslation("release");

  const {
    formState: { errors },
    register,
  } = useFormContext<ReleaseCreateInput>();

  const getErrorMessage = (field: keyof ReleaseCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  return (
    <>
      <div className="fields-wrapper">
        <TextInput
          label={t("form.fields.name")}
          name="name"
          errorMessage={getErrorMessage("name")}
        />
      </div>

      <Textarea
        label={t("form.fields.description")}
        name="description"
        errorMessage={getErrorMessage("description")}
      />

      <div className="fields-wrapper">
        <Select
          label={t("form.fields.type.label")}
          name="typeId"
          options={
            releaseTypes?.map((releaseType) => ({
              label: releaseType.name,
              value: releaseType.id,
            })) ?? []
          }
          placeholder={t("form.fields.type.placeholder")}
        />
        <div className="checkbox-wrapper">
          <input
            id="isMultitenant"
            type="checkbox"
            {...register("isMultitenant")}
          />
          <label htmlFor="isMultitenant">
            {t("form.fields.isMultitenant")}
          </label>
        </div>
      </div>

      <div className="actions-wrapper">
        <div className="form-actions">
          <Button
            label={t("form.actions.cancel")}
            type="button"
            onClick={handleCancel}
            severity="secondary"
            variant="outlined"
          />
          <SubmitButton
            label={actionLabel ?? t("form.actions.submit")}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ReleaseFormFields;
