import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
  TRequestJSON,
} from "@dzangolab/react-ui";
import {
  formatDate,
  Release,
  ReleaseApp,
  WORKSPACE_RELEASE_STATUS,
} from "core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReleaseDuplicateModal, ReleaseFormModal } from "./components";
import { RELEASE_STATUS_CLOSED } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useDeleteReleaseMutation,
  useLazyDeployReleaseQuery,
  useLazyGetReleaseTypesQuery,
  useLazySetupAndBuildReleaseQuery,
  useLazyStopReleaseQuery,
  useMarkReleaseAsReadyMutation,
} from "../../redux/apis/release";
import { useLazyGetWorkspaceReleasesQuery } from "../../redux/apis/workspaces";
import { resetSelectedRelease } from "../../redux/SelectedWorkspacesSlice";

const WorkspaceReleases = () => {
  const { t } = useTranslation("release");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    workspace: currentWorkspace,
    release,
    getReleaseActionStates,
  } = useCurrentWorkspace();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [releaseToDuplicate, setReleaseToDuplicate] = useState<any>();
  const [loadingReleases, setLoadingReleases] = useState<number[]>([]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [markReleaseAsReady] = useMarkReleaseAsReadyMutation();
  const [fetchReleases, { data: releases, isFetching: isLoadingReleases }] =
    useLazyGetWorkspaceReleasesQuery();
  const [setupBuildRelease] = useLazySetupAndBuildReleaseQuery();
  const [fetchReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [deployRelease, { isFetching: isLoadingDeployRelease }] =
    useLazyDeployReleaseQuery();
  const [stopRelease, { isFetching: isLoadingStopRelease }] =
    useLazyStopReleaseQuery();

  const [deleteRelease] = useDeleteReleaseMutation();

  const handleFetchReleases = useCallback(
    (parameters: TRequestJSON) => {
      if (currentWorkspace) {
        fetchReleases({
          workspaceId: currentWorkspace.id,
          filters: parameters.filter,
        });
      }
    },
    [currentWorkspace, fetchReleases],
  );

  useEffect(() => {
    if (currentWorkspace) {
      fetchReleaseTypes({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseTypes]);

  const selectRelease = (release: any) => {
    if (release && currentWorkspace) {
      navigate(
        `/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${release.secondaryIdentifier}`,
      );
    }
  };

  const getReleaseState = (release: any) => {
    const apps = release?.apps ?? [];

    const allInState = (state: string) =>
      apps.every((app: ReleaseApp) => app.status === state);

    const anyInState = (state: string) =>
      apps.some((app: ReleaseApp) => app.status === state);

    if (apps.some((app: ReleaseApp) => !app.status)) {
      return { disablePlay: true, disableStop: true };
    }

    const disablePlay =
      allInState(WORKSPACE_RELEASE_STATUS.BUILDING) ||
      allInState(WORKSPACE_RELEASE_STATUS.RUNNING);
    const disableStop =
      allInState(WORKSPACE_RELEASE_STATUS.BUILDING) ||
      anyInState(WORKSPACE_RELEASE_STATUS.READY) ||
      anyInState(WORKSPACE_RELEASE_STATUS.ERROR) ||
      anyInState(WORKSPACE_RELEASE_STATUS.STOPPED);

    return { disablePlay, disableStop };
  };

  const getReleaseStatusLabel = (release: Release) => {
    if (release.isDraft) {
      return t("release-status.draft");
    }

    if (release.closedAt) {
      return t("release-status.closed");
    }

    const apps = release?.apps ?? [];

    const statusPriority = [
      WORKSPACE_RELEASE_STATUS.BUILDING,
      WORKSPACE_RELEASE_STATUS.RUNNING,
      WORKSPACE_RELEASE_STATUS.READY,
      WORKSPACE_RELEASE_STATUS.STOPPED,
    ];

    for (const status of statusPriority) {
      if (status === WORKSPACE_RELEASE_STATUS.STOPPED) {
        if (apps.every((app: ReleaseApp) => app.status === status)) {
          return status;
        }
      } else if (apps.some((app: ReleaseApp) => app.status === status)) {
        return status;
      }
    }

    return "";
  };

  const setupAndBuildActionHandler = async (selectedRelease: any) => {
    if (currentWorkspace && selectedRelease) {
      const response = await setupBuildRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }
    }
  };

  const deleteReleaseActionhandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      const response = await deleteRelease(selectedRelease.id);

      if (response.data) {
        toast.success(t("table.delete.message.success"));

        if (release && release.id === selectedRelease.id) {
          dispatch(resetSelectedRelease({ workspace: currentWorkspace }));
        }

        fetchReleases({ workspaceId: currentWorkspace.id });
      } else if (response.error) {
        toast.error(t("table.delete.message.error"));
      }
    }
  };

  const onReleaseMarkedAsReady = async (releaseId: number) => {
    if (currentWorkspace) {
      const response = await markReleaseAsReady({
        releaseId,
        workspaceId: currentWorkspace.id,
      });

      if (response.error) {
        toast.error(t("mark-as-ready.message.error"));

        return;
      }

      fetchReleases({ workspaceId: currentWorkspace.id });

      toast.success(t("mark-as-ready.message.success"));
    }
  };

  const renderToolbar = () => {
    return (
      <Button onClick={openModal}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  const playActionHandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      setLoadingReleases((previous) => [...previous, selectedRelease.id]);
      const response = await deployRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      setLoadingReleases((previous) =>
        previous.filter((id) => id !== selectedRelease.id),
      );

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }

      toast.success(t("messages.success.start"));
    }
  };

  const stopActionHandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      setLoadingReleases((previous) => [...previous, selectedRelease.id]);

      const response = await stopRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      setLoadingReleases((previous) =>
        previous.filter((id) => id !== selectedRelease.id),
      );

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }

      toast.success(t("messages.success.stop"));
    }
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Link
            className="release-name-link"
            to={`/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${original.secondaryIdentifier}`}
            onClick={(event_) => {
              event_.preventDefault();
              selectRelease(original);
            }}
          >
            {original.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.type?.name ?? "";
      },
    },
    {
      accessorKey: "state",
      accessorFn: (row) => row.state ?? undefined,
      header: t("table.columns.state"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAState = rowA.original.state?.i18n[0].name || "";
        const rowBState = rowB.original.state?.i18n[0].name || "";

        return rowAState.localeCompare(rowBState);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.state?.i18n[0].name ?? "";
      },
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.createdAt"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return formatDate(original.createdAt);
      },
    },
    {
      accessorKey: "status",
      header: t("table.columns.status"),
      width: "14rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAStatus = getReleaseStatusLabel(rowA.original) || "";
        const rowBStatus = getReleaseStatusLabel(rowB.original) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      enableColumnFilter: true,
      meta: {
        serverFilterFn: "equals",
      },
      customFilterComponent(column) {
        return (
          <div className="checkbox-wrapper">
            <input
              id="statusFilter"
              type="checkbox"
              checked={!!(column.getFilterValue() || false)}
              onChange={(event) => {
                column.setFilterValue(
                  event.target.checked ? RELEASE_STATUS_CLOSED : null,
                );
              }}
            />
            <label htmlFor="statusFilter">
              {t("table.filters.status.checkbox-label")}
            </label>
          </div>
        );
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { disablePlay, disableStop, isProcessing, isStarting } =
          getReleaseActionStates(original);
        const status = getReleaseStatusLabel(original);

        return (
          <div className="status">
            <div className="status-text">{status}</div>
            {disablePlay ? (
              <Button
                iconLeft="pi pi-stop-circle"
                rounded
                variant="textOnly"
                severity="danger"
                onClick={() => {
                  stopActionHandler(original);
                }}
                disabled={
                  loadingReleases.includes(original.id) ||
                  original.isDraft ||
                  isProcessing ||
                  isStarting ||
                  disableStop
                }
              />
            ) : (
              <Button
                iconLeft="pi pi-play-circle"
                variant="textOnly"
                rounded
                severity="success"
                onClick={() => {
                  playActionHandler(original);
                }}
                disabled={
                  loadingReleases.includes(original.id) ||
                  original.isDraft ||
                  isProcessing ||
                  isStarting ||
                  disablePlay
                }
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Page title={t("releases")} toolbar={renderToolbar()}>
      <DataTable
        columns={columns}
        className="releases-table"
        isLoading={isLoadingReleases}
        data={releases || []}
        emptyTableMessage={t("table.empty")}
        initialSorting={[
          {
            id: "createdAt",
            desc: true,
          },
        ]}
        dataActionsMenu={(data) => {
          return {
            actions: [
              {
                label: t("table.actions.view"),
                onClick: (rowData: any) => {
                  selectRelease(rowData);
                },
              },
              {
                label: t("table.actions.duplicate"),
                onClick: (rowData: any) => {
                  setReleaseToDuplicate(rowData);
                },
              },
              {
                confirmationOptions: {
                  message: t("table.mark-as-ready.message", {
                    releaseName: data.name,
                  }),
                  header: t("table.mark-as-ready.header"),
                  acceptButtonOptions: {
                    label: t("table.mark-as-ready.actions.mark-as-ready"),
                  },
                  cancelButtonOptions: {
                    label: t("table.mark-as-ready.actions.cancel"),
                  },
                },
                display: (data) => {
                  return data.isDraft;
                },
                label: t("table.actions.mark-as-ready"),
                onClick: (rowData: any) => {
                  onReleaseMarkedAsReady(rowData.id);
                },
                requireConfirmationModal: true,
              },
              {
                className: "table-action-warning",
                confirmationOptions: {
                  message: t("table.force-setup.message", {
                    releaseName: data.name,
                  }),
                  header: t("table.force-setup.header", {
                    releaseName: data.name,
                  }),
                  acceptButtonOptions: {
                    label: t("table.force-setup.actions.force-setup"),
                  },
                  cancelButtonOptions: {
                    label: t("table.force-setup.actions.cancel"),
                  },
                },
                display: (data) => {
                  return !data.isDraft;
                },
                disabled: (data) => {
                  return !currentWorkspace.isCodeGenerated || data.closedAt;
                },
                label: t("table.actions.force-setup"),
                requireConfirmationModal: true,
                onClick: (rowData: any) => {
                  setupAndBuildActionHandler(rowData);
                },
              },
              {
                className: "table-action-danger",
                confirmationOptions: {
                  message: t("table.delete.message.confirm", {
                    release: data.name,
                  }),
                  header: t("table.delete.header"),
                  acceptButtonOptions: {
                    label: t("table.delete.actions.delete"),
                    className: "danger",
                  },
                  cancelButtonOptions: {
                    label: t("table.delete.actions.cancel"),
                  },
                },
                label: t("table.actions.delete"),
                requireConfirmationModal: true,
                onClick: (rowData: Release) => {
                  deleteReleaseActionhandler(rowData);
                },
              },
            ],
          };
        }}
        fetchData={handleFetchReleases}
      />
      <ReleaseFormModal
        onHide={closeModal}
        visible={isModalVisible}
        releaseTypes={releaseTypes}
      />
      <ReleaseDuplicateModal
        onHide={() => {
          setReleaseToDuplicate(null);
        }}
        release={releaseToDuplicate}
        visible={!!releaseToDuplicate}
        onDuplicate={(secondaryIdentifier: string) => {
          navigate(
            `/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${secondaryIdentifier}`,
          );
        }}
        releaseTypes={releaseTypes}
      />
    </Page>
  );
};

export default WorkspaceReleases;
