import {
  Service,
  SERVICE_STATUS,
  ServiceCreateInput,
  ServiceUpdateInput,
} from "core";

import baseApi, { TagsEnum } from "./base-api";

const servicesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<Service[], { workspaceId: number }>({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services`,
        };
      },
      providesTags: [TagsEnum.Services],
    }),

    createService: build.mutation<Service, ServiceCreateInput>({
      query: (data: ServiceCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/services`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Services],
    }),

    stopService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/stop`,
        };
      },
      async onQueryStarted(row, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex((item) => item.id === row.id);

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.STOPPING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deployService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/deploy`,
        };
      },
      async onQueryStarted(row, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex((item) => item.id === row.id);

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.STARTING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteService: build.mutation<any, { id: number; workspaceId: number }>({
      query: ({ id, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(deletedRow, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: deletedRow.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === deletedRow.id,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.DELETING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateService: build.mutation<
      Service,
      { id: number; data: ServiceUpdateInput; workspaceId: number }
    >({
      query: ({ id, data, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${id}`,
          method: "PUT",
          body: data,
        };
      },
      async onQueryStarted(updatedRow, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: updatedRow.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === updatedRow.id,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.UPDATING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useCreateServiceMutation,
  useLazyGetServicesQuery,
  useLazyStopServiceQuery,
  useLazyDeployServiceQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = servicesApi;
export default servicesApi;
