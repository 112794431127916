import { TextInput, Textarea, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { Workspace } from "core";
type Properties = {
  handleCancel: () => void;
  isLoading: boolean;
  workspace?: Workspace;
  fieldErrors?: { [key: string]: string };
};

type FormFields = {
  description?: string;
  name: string;
};

const WorkspaceFormFields = ({
  isLoading,
  workspace,
  handleCancel,
}: Properties) => {
  const { t } = useTranslation("workspaceForm");

  const {
    formState: { errors },
  } = useFormContext<FormFields>();

  const getErrorMessage = (field: keyof FormFields): string => {
    return errors?.[field]?.message || "";
  };

  return (
    <>
      <TextInput
        label={t("form.fields.name")}
        name="name"
        errorMessage={getErrorMessage("name")}
      />
      <Textarea
        label={t("form.fields.description")}
        name="description"
        errorMessage={getErrorMessage("description")}
      />
      <div className="actions">
        <SubmitButton
          label={
            workspace ? t("form.actions.update") : t("form.actions.submit")
          }
          loading={isLoading}
        />
        {!workspace && (
          <Button
            label={t("form.actions.cancel")}
            type="button"
            onClick={handleCancel}
            severity="secondary"
            variant="outlined"
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceFormFields;
