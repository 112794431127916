import { Textarea, TextInput, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { ReleaseTypeCreateInput } from "core";
import { useEffect } from "react";

interface Properties {
  actionLabel?: string;
  fieldErrors?: Record<string, string>;
  handleCancel: () => void;
  isLoading: boolean;
  isEdit?: boolean;
}

const ReleaseTypeFormFields: React.FC<Properties> = (properties) => {
  const { fieldErrors, actionLabel, handleCancel, isLoading, isEdit } =
    properties;
  const { t } = useTranslation("releaseTypeForm");

  const {
    formState: { errors },
    getFieldState,
    setError,
    setValue,
    watch,
  } = useFormContext<ReleaseTypeCreateInput>();

  const watchName = watch("name");

  const getErrorMessage = (field: keyof ReleaseTypeCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  useEffect(() => {
    if (fieldErrors?.slug) {
      setError("slug", {
        type: "manual",
        message: fieldErrors.slug,
      });
    }
  }, [fieldErrors, setError]);

  useEffect(() => {
    if (!isEdit) {
      const { isDirty } = getFieldState("slug");

      if (!isDirty) {
        const generatedSlug = watchName
          ?.trim()
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^[-~!@#$%^&*()]+/, "")
          .replace(/[-~!@#$%^&*()]+$/, "");

        setValue("slug", generatedSlug);
      }
    }
  }, [getFieldState, setValue, watchName, isEdit]);

  return (
    <>
      <div className="fields-wrapper">
        <TextInput
          name="name"
          placeholder={t("release-types.form.name.placeholder")}
          label={t("release-types.form.name.label")}
          errorMessage={getErrorMessage("name")}
        />

        <TextInput
          name="slug"
          placeholder={t("release-types.form.slug.placeholder")}
          label={t("release-types.form.slug.label")}
          errorMessage={getErrorMessage("slug")}
        />
      </div>

      <Textarea
        name="description"
        placeholder={t("release-types.form.description.placeholder")}
        label={t("release-types.form.description.label")}
      />

      <div className="actions-wrapper">
        <div className="form-actions">
          <Button
            label={t("release-types.actions.cancel")}
            type="button"
            onClick={handleCancel}
            severity="secondary"
            variant="outlined"
          />

          <SubmitButton
            label={actionLabel ?? t("release-types.actions.submit")}
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ReleaseTypeFormFields;
