import { useTranslation } from "@dzangolab/react-i18n";
import { Page, Modal } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";

import { OverviewTab, Toolbar } from "./components";
import LinkReleaseServiceDialog from "./components/LinkReleaseServiceDialog";
import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import { RELEASE_SECTIONS, RELEASE_STATE_APPROVED } from "../../constants";
import AddReleaseApp from "./components/AddReleaseApp";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetReleaseStatesQuery } from "../../redux/apis/release";
import { useLazyGetServicesQuery } from "../../redux/apis/services";

const Overview: React.FC = () => {
  const { t } = useTranslation("app");

  const [addReleaseServiceDialogVisible, setAddReleaseServiceDialogVisible] =
    useState(false);
  const [addReleaseAppDialogVisible, setAddReleaseAppDialogVisible] =
    useState(false);
  const [moduleType, setModuleType] = useState("");

  const [getServices, { data: workspaceServices, isLoading: loadingServices }] =
    useLazyGetServicesQuery();
  const [fetchReleaseStates, { data: releaseStates }] =
    useLazyGetReleaseStatesQuery();

  const { release: currentRelease, workspace: currentWorkspace } =
    useCurrentWorkspace();

  const approvedStateId = releaseStates?.find(
    ({ slug }) => slug === RELEASE_STATE_APPROVED,
  )?.id;

  const isApproved =
    approvedStateId !== undefined &&
    approvedStateId === currentRelease?.stateId;

  useEffect(() => {
    fetchReleaseStates();

    if (currentWorkspace) {
      getServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseStates, getServices]);

  const addReleaseModuleHandler = (type: string, app?: ReleaseApp) => {
    if (app) {
      setModuleType("");
    } else {
      setModuleType(type);
    }

    if (type === RELEASE_SECTIONS.SERVICES) {
      setAddReleaseServiceDialogVisible(true);
    } else {
      setAddReleaseAppDialogVisible(true);
    }
  };

  const hideAddReleaseAppDialog = () => {
    setAddReleaseAppDialogVisible(false);
  };

  return (
    <Page
      className={"overview-page graph"}
      loading={
        loadingServices ||
        (currentWorkspace?.isCodeGenerated ? !currentRelease : false)
      }
      title={<ReleasePageTitle isApproved={isApproved} isEditable={true} />}
      toolbar={<Toolbar isDraft={currentRelease && currentRelease.isDraft} />}
    >
      <OverviewTab addReleaseModuleHandler={addReleaseModuleHandler} />

      <LinkReleaseServiceDialog
        visible={addReleaseServiceDialogVisible}
        onHide={() => setAddReleaseServiceDialogVisible(false)}
        release={currentRelease}
        workspaceServices={workspaceServices ?? []}
      />

      {moduleType !== "" && (
        <Modal
          onHide={hideAddReleaseAppDialog}
          visible={addReleaseAppDialogVisible}
          header={`${t("release-app.add.title", {
            appModule: moduleType.slice(0, -1),
            ns: "appOverview",
          })}`}
          className="add-release-app-dialog"
        >
          <AddReleaseApp
            moduleType={moduleType}
            onHide={hideAddReleaseAppDialog}
          />
        </Modal>
      )}
    </Page>
  );
};

export default Overview;
