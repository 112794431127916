import { useTranslation } from "@dzangolab/react-i18n";
import {
  TableColumnDefinition,
  TDataTable as DataTable,
  Tag,
  Button,
} from "@dzangolab/react-ui";
import { ReleaseType, Workspace } from "core";
import { useState } from "react";
import { toast } from "react-toastify";

import ReleaseTypeFormModal from "./ReleaseTypeFormModal";
import { useDeleteReleaseTypeMutation } from "../../../../redux/apis/release";

interface Properties {
  releaseTypes: ReleaseType[];
  loading: boolean;
  workspace: Workspace;
}

const ReleaseTypesTable: React.FC<Properties> = ({
  releaseTypes,
  loading,
  workspace,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReleaseType, setSelectedReleaseType] = useState<
    ReleaseType | undefined
  >(undefined);

  const { t } = useTranslation("releaseTypeForm");

  const [triggerReleaseType] = useDeleteReleaseTypeMutation();

  const openModal = (releaseType?: ReleaseType) => {
    setSelectedReleaseType(releaseType || undefined);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedReleaseType(undefined);
  };

  const editActionHandler = (rowData: ReleaseType) => {
    openModal(rowData);
  };

  const deleteActionHandler = async (rowData: ReleaseType) => {
    try {
      const response = await triggerReleaseType({
        id: rowData.id,
        workspaceId: workspace.id,
      });

      if ("data" in response) {
        toast.success(t("release-types.message.success.delete"));
      } else if ("error" in response) {
        handleErrorResponse(response.error);
      }
    } catch {
      toast.error(t("release-types.message.error.default-delete"));
    }
  };

  const handleErrorResponse = (error: any) => {
    if (error.data.error === "REPO_ASSOCIATED_WITH_RELEASE_TYPE") {
      toast.error(t("release-types.message.error.repo-associate"));
    } else {
      toast.error(t("release-types.message.error.default-delete"));
    }
  };

  const columns: Array<TableColumnDefinition<ReleaseType>> = [
    {
      accessorKey: "name",
      header: t("release-types.table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: ReleaseType } }) => (
        <span>
          {original.name}
          {original.isSystemDefined && (
            <span className="column-subtitle">
              {t("release-types.table.columns.system-defined")}
            </span>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="release-settings-header">
        <h2>{t("release-types.title")}</h2>
        <Button
          variant="filled"
          iconLeft="pi pi-plus"
          onClick={() => openModal()}
        />
      </div>

      <DataTable
        columns={columns}
        className="release-types-table"
        isLoading={loading}
        data={releaseTypes}
        emptyTableMessage={t("release-types.message.error.no-release-types")}
        initialSorting={[
          {
            id: "name",
            desc: true,
          },
        ]}
        dataActionsMenu={(data) => ({
          actions: [
            {
              label: t("release-types.table.actions.edit"),
              onClick: () => editActionHandler(data),
              disabled: data.isSystemDefined,
            },
            {
              className: "table-action-danger",
              confirmationOptions: {
                header: t("confirmation.delete.header"),
                message: t("confirmation.delete.message", {
                  releaseType: data.name,
                }),
                acceptButtonOptions: {
                  label: t("confirmation.delete.actions.delete"),
                  className: "danger",
                },
                cancelButtonOptions: {
                  label: t("confirmation.delete.actions.cancel"),
                },
              },
              label: t("release-types.table.actions.delete"),
              onClick: () => deleteActionHandler(data),
              disabled: data.isSystemDefined,
              requireConfirmationModal: true,
            },
          ],
          displayActions: !data.isSystemDefined,
        })}
      />

      <ReleaseTypeFormModal
        onHide={closeModal}
        visible={isModalVisible}
        releaseType={selectedReleaseType}
      />
    </>
  );
};

export default ReleaseTypesTable;
