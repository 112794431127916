import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  ConfirmationModal,
  Page,
  SubPane,
  TabbedPanel,
  Tooltip,
} from "@dzangolab/react-ui";
import { ReleaseApp, Repository } from "core";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BranchRules } from "./components";
import { GeneralInfo } from "./GeneralInfo";
import config from "../../../config";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useLazyGetRuleTypesQuery } from "../../../redux/apis/branch-rules";
import { useLazyGetReleaseTypesQuery } from "../../../redux/apis/release";
import {
  useDeleteRepositoryMutation,
  useLazyGetRepositoryBranchRulesQuery,
  useLazyGetRepositoryQuery,
} from "../../../redux/apis/repositories";

interface SuccessResponse {
  data: {
    id: number;
  };
}

interface ErrorResponse {
  error: {
    data: {
      error: string;
    };
  };
}

const RepositoryDetail = () => {
  const { t } = useTranslation("repositoryDetails");
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  const [getReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [getRuleTypes, { data: ruleTypes }] = useLazyGetRuleTypesQuery();
  const [getRepository, { data: repository, isFetching: isRepositoryLoading }] =
    useLazyGetRepositoryQuery();
  const [
    fetchBranchRules,
    { data: branchRules, isFetching: isBranchRuleLoading },
  ] = useLazyGetRepositoryBranchRulesQuery();

  const [triggerDelete, { isLoading }] = useDeleteRepositoryMutation();

  const tooltipReference = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (currentWorkspace && id) {
        await Promise.all([
          getRepository({ workspaceId: currentWorkspace.id, id: Number(id) }),
          getReleaseTypes({ workspaceId: currentWorkspace.id }),
          getRuleTypes({}),
        ]);
      }

      if (currentWorkspace && repository) {
        fetchBranchRules({
          workspaceId: currentWorkspace.id,
          id: repository.id,
        });

        setPageTitle(repository.name);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace, id, repository]);

  const generateTabs = (): React.ReactElement[] => {
    const tabs: React.ReactElement[] = [];

    tabs.push(
      <SubPane title={t("title.general")} key="general">
        <GeneralInfo
          repository={repository as Repository}
          setPageTitle={setPageTitle}
        />
      </SubPane>,
    );

    if (config.features.branchFilter) {
      tabs.push(
        <SubPane title={t("title.rules")} key="branch-rule">
          <BranchRules
            branchRules={branchRules}
            isLoading={isBranchRuleLoading}
            releaseTypes={releaseTypes}
            repositoryId={repository?.id!}
            ruleTypes={ruleTypes}
            workspace={currentWorkspace}
          />
        </SubPane>,
      );
    }

    return tabs;
  };

  const deleteHandler = async () => {
    if (!repository) return;

    const { id, workspaceId } = repository;

    let response = (await triggerDelete({
      workspaceId,
      repositoryId: id,
    })) as SuccessResponse | ErrorResponse;

    if ("error" in response) {
      const errorMessage =
        response.error.data.error === "ERROR_REPOSITORY_IN_USE"
          ? t("messages.delete.repositoryInUse")
          : t("messages.delete.error");

      toast.error(errorMessage);
    } else {
      navigate(
        `/workspaces/${currentWorkspace.secondaryIdentifier}/repositories`,
      );

      toast.success(t("messages.delete.success"));
    }

    setShowDeleteConfirmation(false);
  };

  return (
    <Page
      title={pageTitle}
      className="repository-details-page"
      loading={isRepositoryLoading}
      toolbar={
        <>
          {(repository as Repository & { apps?: ReleaseApp[] })?.apps?.length! >
            0 && (
            <Tooltip elementRef={tooltipReference} position="left" mouseTrack>
              <span>{t("messages.delete.disable")}</span>
            </Tooltip>
          )}
          <span ref={tooltipReference}>
            <Button
              disabled={
                (repository as Repository & { apps?: ReleaseApp[] })?.apps
                  ?.length! > 0
              }
              iconLeft={"pi pi-trash"}
              severity="danger"
              onClick={() => setShowDeleteConfirmation(true)}
            />
          </span>
        </>
      }
    >
      <TabbedPanel
        id="repository-detail-general"
        persistState={isBranchRuleLoading && isRepositoryLoading ? true : false}
      >
        {generateTabs()}
      </TabbedPanel>

      <ConfirmationModal
        accept={deleteHandler}
        header={t("confirmation.header")}
        message={t("confirmation.message", {
          repositoryName: repository?.name,
        })}
        acceptButtonOptions={{
          loading: isLoading,
          severity: "danger",
          label: t("confirmation.actions.delete"),
        }}
        cancelButtonOptions={{
          label: t("confirmation.actions.cancel"),
        }}
        visible={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
      />
    </Page>
  );
};

export default RepositoryDetail;
