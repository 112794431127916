import { useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Textarea } from "@dzangolab/react-ui";
import { useState } from "react";

import NameValuePairInput from "./NameValuePairInput";

interface Properties {
  addButtonLabel: string;
  errorMessage?: string;
  fields: Record<"id", string>[];
  inputName: string;
  onAdd: () => void;
  onRemove: (index: number) => void;
  title: React.ReactNode | string;
}

const EDITOR_MODES = {
  SIMPLE: "simple",
  ADVANCED: "advanced",
} as const;

type EditorMode = (typeof EDITOR_MODES)[keyof typeof EDITOR_MODES];

const parseArrayToText = (
  input: Record<string, string | number | boolean>[],
): string => input.map(({ name, value }) => `${name}=${value}`).join("\n");

const parseTextToArray = (
  input: string,
): Record<string, string | number | boolean>[] =>
  input
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line && !line.startsWith("#"))
    .map((line) => {
      const [key, rawValue] = line.split("=").map((part) => part.trim());
      const value = rawValue?.includes("#")
        ? rawValue.split("#")[0].trim()
        : rawValue || "";

      return { name: key, value };
    });

const EnvironmentVariablesInput: React.FC<Properties> = ({
  addButtonLabel,
  errorMessage,
  fields,
  inputName,
  onAdd,
  onRemove,
  title,
}) => {
  const { t } = useTranslation("services");
  const { getValues, setValue } = useFormContext();

  const [mode, setMode] = useState<EditorMode>(EDITOR_MODES.SIMPLE);
  const [text, setText] = useState("");

  const isSimpleMode = mode === EDITOR_MODES.SIMPLE;
  const modeButtonLabel = isSimpleMode
    ? t("environmentVariablesSection.buttons.mode.advanced")
    : t("environmentVariablesSection.buttons.mode.simple");

  const toggleMode = () => {
    if (isSimpleMode) {
      const arrayInput: Record<string, string>[] = getValues(inputName);

      setText(parseArrayToText(arrayInput));
    } else {
      setValue(inputName, parseTextToArray(text));
    }

    setMode((previous) =>
      previous === EDITOR_MODES.SIMPLE
        ? EDITOR_MODES.ADVANCED
        : EDITOR_MODES.SIMPLE,
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;

    setText(inputText);
    setValue(inputName, parseTextToArray(inputText));
  };

  const renderAdvancedEditor = () => {
    return (
      <Textarea name="text" value={text} onChange={handleChange} rows={10} />
    );
  };

  const renderSimpleEditor = () => {
    return fields.map((item, index) => (
      <NameValuePairInput
        key={item.id}
        fieldName={inputName}
        index={index}
        onRemove={() => onRemove(index)}
      />
    ));
  };

  return (
    <div className="environment-variables-section">
      <div className="title-wrapper">
        {typeof title === "string" ? <p>{title}</p> : title}

        {isSimpleMode && (
          <Button
            label={addButtonLabel}
            type="button"
            variant="outlined"
            onClick={onAdd}
          />
        )}
      </div>

      <button
        className="editor-mode-toggler"
        type="button"
        onClick={toggleMode}
      >
        {modeButtonLabel}
      </button>

      {isSimpleMode ? renderSimpleEditor() : renderAdvancedEditor()}
      <span className="error-message">{errorMessage ? errorMessage : ""}</span>
    </div>
  );
};

export default EnvironmentVariablesInput;
