import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  DropdownMenu,
  IButtonProperties,
  MenuItem,
} from "@dzangolab/react-ui";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReleaseApp } from "core";
import React from "react";
import { toast } from "react-toastify";

import { useCurrentWorkspace } from "../hooks/UseCurrentWorkspace";

type ActionsProperties = {
  disableStop?: boolean;
  disablePlay?: boolean;
  hideShareMenu?: boolean;
  isStarting?: boolean;
  isStopping?: boolean;
  onStop: () => void;
  onPlay: () => void;
  onShare: () => void;
};

const Actions = ({
  disableStop,
  disablePlay,
  hideShareMenu = false,
  isStarting,
  isStopping,
  onStop,
  onPlay,
}: ActionsProperties) => {
  const { t } = useTranslation("release");
  const { release } = useCurrentWorkspace();

  const canPlay = disableStop;
  const isActionDisabled = disablePlay && disableStop;

  const getActionStates = () => {
    let actionIcon: React.ReactNode;
    let runningStatusLabel = "";
    let severity: IButtonProperties["severity"];

    const playIcon = (
      <FontAwesomeIcon
        icon={faPlay}
        size={"lg"}
        className="release-play-icon"
      />
    );

    const stopIcon = <FontAwesomeIcon icon={faStop} size={"lg"} />;

    if (isStarting) {
      actionIcon = playIcon;
      runningStatusLabel = t("running-status.starting");
      severity = "success";
    } else if (isStopping) {
      actionIcon = stopIcon;
      runningStatusLabel = t("running-status.stopping");
      severity = "warning";
    } else {
      actionIcon = canPlay ? playIcon : stopIcon;
      runningStatusLabel = canPlay
        ? t("running-status.stopped")
        : t("running-status.running");
      severity = canPlay ? "success" : "warning";
    }

    return { actionIcon, runningStatusLabel, severity };
  };

  const { actionIcon, runningStatusLabel, severity } = getActionStates();

  const onClick = canPlay ? onPlay : onStop;

  const onShare = (app: ReleaseApp) => {
    if (!app || !app.url) return;

    navigator.clipboard.writeText(app.url);

    toast.success(t("messages.shareApp", { appName: app.name }));
  };

  const menuItems: MenuItem[] =
    release?.apps?.map((app: ReleaseApp) => ({
      disabled: !app.url,
      label: app.name,
      onClick: () => onShare(app),
    })) || [];

  const renderRunningStatus = () => {
    return (
      <div className="running-status">
        <span>{runningStatusLabel}</span>
      </div>
    );
  };

  return (
    <div className="workspace-actions">
      {!hideShareMenu && menuItems.length > 0 && (
        <DropdownMenu
          menu={menuItems}
          position="bottom-end"
          label={t("share-button.label")}
        />
      )}
      {renderRunningStatus()}
      <Button
        variant="filled"
        severity={severity}
        iconLeft={actionIcon}
        rounded
        disabled={isActionDisabled}
        onClick={onClick}
      />
    </div>
  );
};

export default Actions;
