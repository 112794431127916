import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  ConfirmationModal,
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { SERVICE_STATUS, SERVICE_TYPES, Service, getKeyFromValue } from "core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import ServiceFormModal from "./components/ServiceFormModal";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyDeployServiceQuery,
  useLazyGetServicesQuery,
  useLazyStopServiceQuery,
} from "../../redux/apis/services";

const Services = () => {
  const { t } = useTranslation("services");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const [isStartModalVisible, setIsStartModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<Service>();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsStartModalVisible(false);
    setIsStopModalVisible(false);
    setIsModalVisible(false);
    setSelectedService(undefined);
  };

  const [fetchServices, { data: workspaceServices, isFetching: isLoading }] =
    useLazyGetServicesQuery();

  const [stopService, { isFetching: isStoppingService }] =
    useLazyStopServiceQuery();

  const [stoppingServiceIds, setStoppingServiceIds] = useState<number[]>([]);
  const [startingServiceIds, setStartingServiceIds] = useState<number[]>([]);

  const stopActionHandler = async (service: Service) => {
    closeModal();
    setStoppingServiceIds([...stoppingServiceIds, service.id]);

    const response = await stopService({
      id: service.id,
      workspaceId: service.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.stop"));

      return;
    }

    setStoppingServiceIds((ids) => ids.filter((id) => id !== service.id));
  };

  const editActionHandler = (service: Service) => {
    setSelectedService(service);
    openModal();
  };

  useEffect(() => {
    if (currentWorkspace) {
      fetchServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchServices]);

  const [deployService, { isFetching: isDeployingService }] =
    useLazyDeployServiceQuery();

  const buildActionHandler = async (selectedService: Service) => {
    closeModal();
    setStartingServiceIds([...startingServiceIds, selectedService.id]);

    const response = await deployService({
      id: selectedService.id,
      workspaceId: selectedService.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.start"));

      return;
    }

    setStartingServiceIds((ids) =>
      ids.filter((id) => id !== selectedService.id),
    );
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => (
        <>
          <span>{original.name}</span>
          {original.isPublic &&
            original.statusId === 2 &&
            original.publicUrl && (
              <a
                className="service-name-link"
                href={original.publicUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="pi pi-external-link"></i>
              </a>
            )}
        </>
      ),
    },
    {
      accessorKey: "typeId",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAType =
          getKeyFromValue(SERVICE_TYPES, rowA.original.typeId) || "";
        const rowBType =
          getKeyFromValue(SERVICE_TYPES, rowB.original.typeId) || "";

        return rowAType.localeCompare(rowBType);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return getKeyFromValue(SERVICE_TYPES, original.typeId) || "";
      },
    },
    {
      accessorKey: "statusId",
      accessorFn: (row) => row.statusId ?? undefined,
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAStatus =
          getKeyFromValue(SERVICE_STATUS, rowA.original.statusId) || "";
        const rowBStatus =
          getKeyFromValue(SERVICE_STATUS, rowB.original.statusId) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const status = getKeyFromValue(SERVICE_STATUS, original.statusId) || "";

        return (
          <div className="status">
            <div className="status-text">{status}</div>
            {original.statusId === SERVICE_STATUS.RUNNING ||
            original.statusId === SERVICE_STATUS.STARTING ? (
              <Button
                iconLeft="pi pi-stop-circle"
                rounded
                variant="textOnly"
                severity="danger"
                onClick={() => {
                  setSelectedService(original);
                  setIsStopModalVisible(true);
                }}
                disabled={
                  original.statusId !== SERVICE_STATUS.RUNNING ||
                  (stoppingServiceIds.includes(original.id) &&
                    isStoppingService)
                }
              />
            ) : (
              <Button
                iconLeft="pi pi-play-circle"
                variant="textOnly"
                severity="success"
                onClick={() => {
                  setSelectedService(original);
                  setIsStartModalVisible(true);
                }}
                disabled={
                  original.statusId === SERVICE_STATUS.STOPPING ||
                  (startingServiceIds.includes(original.id) &&
                    isDeployingService)
                }
              />
            )}
          </div>
        );
      },
    },
    {
      id: "action",
      width: "5rem",
      header: () => <i className="pi pi-cog"></i>,
      dataType: "other",
      cell: ({ row: { original } }) => (
        <div className="actions">
          <Button
            iconLeft="pi pi-pen-to-square"
            variant="textOnly"
            onClick={() => {
              editActionHandler(original);
            }}
          />
        </div>
      ),
    },
  ];

  const renderToolbar = () => {
    return (
      <Button onClick={openModal}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  return (
    <Page title={t("title")} loading={isLoading} toolbar={renderToolbar()}>
      {workspaceServices && (
        <DataTable
          columns={columns}
          className="workspace-services-table"
          isLoading={isLoading}
          data={workspaceServices}
          emptyTableMessage={t("table.empty")}
          initialSorting={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      )}

      <ServiceFormModal
        visible={isModalVisible}
        onHide={closeModal}
        service={selectedService}
      />

      <ConfirmationModal
        header={t("confirmation.stop.header")}
        message={t("confirmation.stop.message", {
          service: selectedService?.name,
        })}
        visible={isStopModalVisible}
        acceptButtonOptions={{
          label: t("confirmation.stop.actions.stop"),
          severity: "danger",
        }}
        cancelButtonOptions={{
          label: t("confirmation.stop.actions.cancel"),
        }}
        onHide={closeModal}
        accept={() => stopActionHandler(selectedService as Service)}
      />

      <ConfirmationModal
        header={t("confirmation.start.header")}
        message={t("confirmation.start.message", {
          service: selectedService?.name,
        })}
        acceptButtonOptions={{
          label: t("confirmation.start.actions.start"),
        }}
        cancelButtonOptions={{
          label: t("confirmation.start.actions.cancel"),
        }}
        visible={isStartModalVisible}
        onHide={closeModal}
        accept={() => buildActionHandler(selectedService as Service)}
      />
    </Page>
  );
};

export default Services;
