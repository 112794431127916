import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal, Input } from "@dzangolab/react-ui";
import { Workspace } from "core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ROUTES } from "../../../../constants";
import { useDeleteWorkspaceMutation } from "../../../../redux/apis/workspaces";

interface Properties {
  visible: boolean;
  onHide: () => void;
  workspace: Workspace;
}
const DeleteConfirmationModal: React.FC<Properties> = ({
  onHide,
  visible,
  workspace,
}) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation("workspaceDelete");
  const [triggerDelete, { data, isLoading: isDeleting, error }] =
    useDeleteWorkspaceMutation();
  const navigate = useNavigate();

  const handleDelete = () => {
    triggerDelete(workspace.id);
    onHide();
  };

  useEffect(() => {
    if (!isDeleting && data) {
      toast.success(t("messages.success"));
      navigate(ROUTES.WORKSPACES);
    }
  }, [isDeleting, data, navigate, t]);

  useEffect(() => {
    if (!isDeleting && error) {
      toast.error(t("messages.error"));
    }
  }, [isDeleting, error, t]);

  return (
    <>
      {workspace && (
        <ConfirmationModal
          accept={handleDelete}
          acceptButtonOptions={{
            disabled: value !== workspace.name || isDeleting,
            label: t("confirmDialog.buttonLabel.confirm"),
            severity: "danger",
          }}
          cancelButtonOptions={{
            label: t("confirmDialog.buttonLabel.cancel"),
          }}
          className="workspace-delete-modal"
          header={t("confirmDialog.header")}
          message={`${t("confirmDialog.message")} "${workspace.name}"`}
          onHide={onHide}
          visible={visible}
        >
          <Input
            type="text"
            onChange={(event) => setValue(event.target.value)}
            value={value}
            className="confirmation-input-form"
          />
        </ConfirmationModal>
      )}
    </>
  );
};

export default DeleteConfirmationModal;
