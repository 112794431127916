import {
  getUserProtectedRoutes,
  getUserPublicRoutes,
  ProtectedRoutesHandler,
  PublicRoutesHandler,
} from "@dzangolab/react-user";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { feature } from "./config";
import { ROUTES } from "./constants";
import { BasicLayout, PublicLayout, WorkspaceLayout } from "./layouts";
import Dashboard from "./views/Dashboard";
import DataModel from "./views/DataModel";
import Documents from "./views/Docs";
import Document from "./views/Docs/Document";
import DocumentPlayground from "./views/DocsPlayground";
import Glossary from "./views/Glossary";
import AddFirstTermPage from "./views/Glossary/AddFirstTerm";
import AddGlossary from "./views/Glossary/AddGlossary";
import AddGlossaryEntry from "./views/Glossary/AddGlossaryEntry";
import EditGlossaryEntry from "./views/Glossary/EditGlossaryEntry";
import StartGlossaryPage from "./views/Glossary/StartGlossary";
import NotFound from "./views/NotFound";
import Overview from "./views/Overview";
import Repositories from "./views/Repositories";
import AddRepository from "./views/Repositories/Create";
import RepositoryDetail from "./views/Repositories/detail";
import WorkspaceServices from "./views/Services";
import DocumentationSettings from "./views/Settings/DocumentationSettings";
import CreateDocumentTypes from "./views/Settings/DocumentationSettings/CreateDocumentTypes";
import EditDocumentTypes from "./views/Settings/DocumentationSettings/EditDocumentTypes";
import GeneralSettings from "./views/Settings/GeneralSettings";
import GlossarySettings from "./views/Settings/GlossarySettings";
import ReleaseTypeSettings from "./views/Settings/ReleaseType";
import Team from "./views/Team";
import WorkspaceReleases from "./views/WorkspaceReleases";
import Workspaces from "./views/Workspaces";
import AddWorkspace from "./views/Workspaces/Create";

export const AppRouter = () => {
  const renderDocumentationRoutes = () => {
    return (
      <>
        <Route
          path={ROUTES.WORKSPACE_DOCUMENT_TYPE_CREATE}
          element={<CreateDocumentTypes />}
        />
        <Route path={ROUTES.DOCS_PLAYGROUND} element={<DocumentPlayground />} />
        <Route
          path={ROUTES.WORKSPACE_DOCUMENT_TYPE_EDIT}
          element={<EditDocumentTypes />}
        />
        <Route path={ROUTES.WORKSPACE_DOCS} element={<Documents />} />
        <Route path={ROUTES.WORKSPACE_DOCUMENT} element={<Document />} />
        <Route
          path={ROUTES.WORKSPACE_SETTINGS_DOCUMENTATION}
          element={<DocumentationSettings />}
        />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        {/* auth routes */}
        <Route element={<ProtectedRoutesHandler />}>
          <Route element={<BasicLayout />}>
            <Route path={ROUTES.WORKSPACES} element={<Workspaces />}></Route>
            <Route
              path={ROUTES.WORKSPACE_CREATE}
              element={<AddWorkspace />}
            ></Route>

            {getUserProtectedRoutes()}
          </Route>

          <Route element={<WorkspaceLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={ROUTES.WORKSPACE_GLOSSARY} element={<Glossary />} />
            <Route
              path={ROUTES.WORKSPACE_GLOSSARY_START}
              element={<StartGlossaryPage />}
            />
            <Route
              path={ROUTES.WORKSPACE_GLOSSARY_ADD_FIRST_TERM}
              element={<AddFirstTermPage />}
            />
            <Route
              path={ROUTES.WORKSPACE_GLOSSARY_NEW}
              element={<AddGlossary />}
            />
            <Route
              path={ROUTES.WORKSPACE_GLOSSARY_ENTRY_EDIT}
              element={<EditGlossaryEntry />}
            />
            <Route
              path={ROUTES.WORKSPACE_GLOSSARY_ENTRY_NEW}
              element={<AddGlossaryEntry />}
            />
            <Route path={ROUTES.WORKSPACE_DATA_MODEL} element={<DataModel />} />
            <Route path={ROUTES.WORKSPACE_RELEASE} element={<Overview />} />
            <Route path={ROUTES.WORKSPACE_TEAM} element={<Team />} />
            <Route
              path={ROUTES.WORKSPACE_SETTINGS_GENERAL}
              element={<GeneralSettings />}
            />
            <Route
              path={ROUTES.WORKSPACE_SETTINGS_GLOSSARY}
              element={<GlossarySettings />}
            />
            <Route
              path={ROUTES.WORKSPACE_RELEASES}
              element={<WorkspaceReleases />}
            />
            <Route
              path={ROUTES.WORKSPACE_REPOSITORIES}
              element={<Repositories />}
            />
            <Route
              path={ROUTES.WORKSPACE_REPOSITORY_CREATE}
              element={<AddRepository />}
            />
            <Route
              path={ROUTES.WORKSPACE_REPOSITORY_DETAIL}
              element={<RepositoryDetail />}
            />
            <Route path={ROUTES.SERVICES} element={<WorkspaceServices />} />
            <Route
              path={ROUTES.WORKSPACE_RELEASE_TYPE}
              element={<ReleaseTypeSettings />}
            />

            {feature("documentation") && renderDocumentationRoutes()}
          </Route>
        </Route>

        {/* public routes */}
        <Route element={<PublicLayout />}>
          <Route element={<PublicRoutesHandler />}>
            {getUserPublicRoutes()}
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
