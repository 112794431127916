import { UserEnabledSidebarOnlyLayout } from "@dzangolab/react-user";
import { Outlet } from "react-router-dom";

import { WebSocketProvider } from "../WebSocketContext";
import { Footer } from "./components/Footer";

export const BasicLayout = (): JSX.Element => {
  return (
    <WebSocketProvider>
      <UserEnabledSidebarOnlyLayout
        className="basic-layout"
        noLocaleSwitcher={false}
        children={<Outlet />}
        displayNavIcons={true}
        noSidebarFooter
      />
      <Footer />
    </WebSocketProvider>
  );
};
