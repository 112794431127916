import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal, Message } from "@dzangolab/react-ui";
import { GlossaryEntriesI18n, GlossaryEntry } from "core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useDeleteGlossaryEntryMutation } from "../../../redux/apis/glossary";

import { AlphabetNavbar } from "./";

interface Properties {
  workspaceSecondaryIdentifier: string;
  glossaryId: number;
  entries: GlossaryEntry[];
  locale: string;
  glossaryLocales: {
    value: string;
    label: string;
  }[];
}

const GlossaryEntries: React.FC<Properties> = (properties) => {
  const {
    entries,
    locale,
    glossaryId,
    glossaryLocales,
    workspaceSecondaryIdentifier,
  } = properties;
  const navigate = useNavigate();
  const { t } = useTranslation("glossary");

  const [groupedGlossaryEntries, setGroupedGlossaryEntries] = useState<{
    [key: string]: GlossaryEntriesI18n[];
  }>();
  const [localesMissingTranslations, setLocalesMissingTranslations] = useState<
    string[]
  >([]);

  const [deleteGlossaryEntry] = useDeleteGlossaryEntryMutation();

  const [glossaryEntryToDelete, setGlossaryEntryToDelete] =
    useState<GlossaryEntriesI18n | null>();

  const navigateToEditEntry = (entryId: number) => {
    navigate(
      `/workspaces/${workspaceSecondaryIdentifier}/glossary/${glossaryId}/entry/${entryId}/edit`,
    );
  };

  const filterEntriesByLocale = (entries: GlossaryEntry[], locale: string) => {
    let filteredEntries: GlossaryEntriesI18n[] = [];

    for (let entry of entries) {
      const localeEntry = entry.i18n.find((i) => i.locale === locale);

      if (localeEntry && localeEntry.term !== "") {
        filteredEntries.push({
          id: entry.id,
          term: localeEntry.term || "",
          definition: localeEntry.definition || "",
          locale: localeEntry.locale || "",
        });
      }
    }

    return filteredEntries;
  };

  const sortEntriesByAlphabet = (entries: any) => {
    for (let entry in entries) {
      entries[entry].sort((a: GlossaryEntriesI18n, b: GlossaryEntriesI18n) => {
        return a.term.localeCompare(b.term);
      });
    }
  };

  const groupEntriesByAlphabet = useCallback(
    (entries: GlossaryEntriesI18n[]) => {
      const groupedEntries: { [key: string]: GlossaryEntriesI18n[] } = {};

      for (let entry of entries) {
        const firstLetter = entry.term.charAt(0).toUpperCase();

        if (!groupedEntries[firstLetter]) {
          groupedEntries[firstLetter] = [];
        }

        groupedEntries[firstLetter].push(entry);
      }

      sortEntriesByAlphabet(groupedEntries);

      return groupedEntries;
    },
    [],
  );

  useEffect(() => {
    const filteredEntries = filterEntriesByLocale(entries, locale);
    const groupedEntries = groupEntriesByAlphabet(filteredEntries);

    setGroupedGlossaryEntries(groupedEntries);
  }, [entries, groupEntriesByAlphabet, locale]);

  useEffect(() => {
    setLocalesMissingTranslations([]);
    if (glossaryLocales.length > 0 && entries.length > 0) {
      const missingLocalesSet: Set<string> = new Set();

      for (const entry of entries) {
        const entryLocales: string[] = entry.i18n.map((i) => i.locale);

        for (const locale of glossaryLocales) {
          if (!entryLocales.includes(locale.value)) {
            missingLocalesSet.add(locale.value);
          }
        }
      }

      const missingLocales: string[] = Array.from(missingLocalesSet);

      setLocalesMissingTranslations(missingLocales);
    }
  }, [entries, glossaryLocales]);

  const onLetterClick = (letter: string) => {
    const section = document.querySelector(
      `#glossary-letter-group-${letter.toUpperCase()}`,
    );

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getGroupedGlossaryEntriesCount = () => {
    let groupedEntriesCount: { [key: string]: number } = {};

    if (!groupedGlossaryEntries) return groupedEntriesCount;

    for (let key in groupedGlossaryEntries) {
      groupedEntriesCount[key] = groupedGlossaryEntries[key].length;
    }

    return groupedEntriesCount;
  };

  const deleteGlossaryEntryHandler = async () => {
    if (!glossaryEntryToDelete) return;

    let response = await deleteGlossaryEntry({
      entryId: glossaryEntryToDelete.id,
      glossaryId,
    });

    if ("error" in response) {
      toast.error(t("glossary-entry.delete.message.error"));
    } else {
      toast.success(t("glossary-entry.delete.message.success"));
    }

    setGlossaryEntryToDelete(null);
    window.location.reload();
  };

  const getEntryDeleteConfirmationModal = () => {
    return (
      <ConfirmationModal
        onHide={() => setGlossaryEntryToDelete(null)}
        key={`delete-confirmation-modal-${glossaryEntryToDelete?.id}`}
        visible={glossaryEntryToDelete != null}
        header={t("glossary-entry.delete.modal.title")}
        message={t("glossary-entry.delete.modal.message", {
          entry: glossaryEntryToDelete?.term,
        })}
        acceptButtonOptions={{
          severity: "danger",
          label: t("glossary-entry.delete.modal.actions.delete"),
        }}
        cancelButtonOptions={{
          label: t("glossary-entry.delete.modal.actions.cancel"),
        }}
        accept={() => deleteGlossaryEntryHandler()}
        reject={() => setGlossaryEntryToDelete(null)}
      />
    );
  };

  const glossaryKeys =
    groupedGlossaryEntries && Object.keys(groupedGlossaryEntries).sort();

  if (entries.length > 0)
    return (
      <>
        <AlphabetNavbar
          onLetterClick={onLetterClick}
          entriesCount={getGroupedGlossaryEntriesCount()}
          glossaryKeys={glossaryKeys}
        />
        {localesMissingTranslations.length > 0 && (
          <Message
            message={t("glossary-entry.message.missing-entries", {
              locales: localesMissingTranslations.join(", "),
            })}
          />
        )}
        <div className="glossary-entries">
          {glossaryKeys &&
            glossaryKeys.map((key) => {
              const letterEntries = groupedGlossaryEntries[key];

              return (
                <dl
                  className="glossary-letter-group"
                  key={`glossary-letter-group-${key}`}
                  id={`glossary-letter-group-${key}`}
                >
                  {letterEntries.map((entry) => {
                    return (
                      <Fragment key={`glossary-entry-${entry.id}`}>
                        <dt>
                          {entry.term}
                          <div className="entry-actions">
                            <Button
                              variant="textOnly"
                              iconLeft="pi pi-trash"
                              severity={"danger"}
                              className="glossary-entry-delete-button"
                              onClick={() => setGlossaryEntryToDelete(entry)}
                            />
                            <Button
                              variant="textOnly"
                              iconLeft="pi pi-pencil"
                              className="glossary-entry-edit-button"
                              onClick={() => navigateToEditEntry(entry.id)}
                            />
                          </div>
                        </dt>
                        <dd>{entry.definition}</dd>
                      </Fragment>
                    );
                  })}
                </dl>
              );
            })}
        </div>
        {getEntryDeleteConfirmationModal()}
      </>
    );

  if (entries.length === 0)
    return <Message message={t("glossary-entry.message.no-entries")} />;

  return <></>;
};

export default GlossaryEntries;
