import { useTranslation } from "@dzangolab/react-i18n";
import { DropdownMenu } from "@dzangolab/react-ui";
import { logout, useUser } from "@dzangolab/react-user";
import { UserRound } from "lucide-react";
import { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ROUTES } from "../../constants";
import baseApi from "../../redux/apis/base-api";
import { resetSelectedWorkspaces } from "../../redux/SelectedWorkspacesSlice";

export const Footer: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation("app");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const userMenu = generateUserMenu(t, navigate, !!user);

  const signout = async () => {
    if (await logout()) {
      setUser(null);
      handleLogout(dispatch);
      toast.success(
        t("logout.message", {
          ns: "user",
        }),
      );
    }
  };

  return (
    <div className="layout-footer">
      {children}

      <DropdownMenu
        className="user-menu"
        hideDropdownIcon
        label={<UserRound size={49} strokeWidth={1} />}
        menu={[
          ...userMenu,
          {
            icon: "pi pi-power-off",
            label: t("header.menu.logout"),
            onClick: signout,
          },
        ]}
      />
    </div>
  );
};

const generateUserMenu = (
  t: (key: string) => string,
  navigate: (route: string) => void,
  isUser?: boolean,
) => {
  if (!isUser) return [];

  return [
    {
      icon: "pi pi-lock",
      label: t("user:changePassword.title"),
      route: ROUTES.CHANGE_PASSWORD,
      onClick: () => navigate(ROUTES.CHANGE_PASSWORD),
    },
    {
      icon: "pi pi-user",
      label: t("user:userMenu.profile"),
      route: ROUTES.PROFILE,
      onClick: () => navigate(ROUTES.PROFILE),
    },
  ];
};

const handleLogout = (dispatch: any) => {
  dispatch(resetSelectedWorkspaces());
  dispatch(baseApi.util.resetApiState());
};
