import {
  NumberInput,
  Select,
  Textarea,
  TextInput,
  useFieldArray,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { Service, SERVICE_TYPES, ServiceCreateInput } from "core";
import { useEffect } from "react";
import { toast } from "react-toastify";

import RegistryFormFields from "./RegistryFormFields";
import { EnvironmentVariablesInput } from "../../../../components";

interface Properties {
  handleCancel: () => void;
  isLoading: boolean;
  service?: Service;
  onDelete?: () => void;
  onNameChange?: (name: string) => void;
}

const ServiceFormFields: React.FC<Properties> = ({
  handleCancel,
  isLoading,
  service,
  onDelete,
  onNameChange,
}) => {
  const { t } = useTranslation("services");
  const {
    setValue,
    formState: { errors },
    register,
  } = useFormContext<ServiceCreateInput>();

  const { fields, append, remove } = useFieldArray({
    name: "envVars",
  });

  const watchTypeId: number = useWatch({ name: "typeId" });
  const watchName = useWatch({ name: "name" });

  const getErrorMessage = (field: keyof ServiceCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  useEffect(() => {
    if (!watchTypeId && service) {
      setValue("typeId", service.typeId);
    }
  }, [service, setValue, watchTypeId]);

  useEffect(() => {
    if (onNameChange) {
      onNameChange(watchName);
    }
  }, [onNameChange, watchName]);

  const copyIdentifier = () => {
    if (service) {
      navigator.clipboard.writeText(service.secondaryIdentifier);
      toast.success(t("messages.success.copyIdentifier"));
    }
  };

  return (
    <>
      <div className="fields-wrapper form-row">
        <TextInput
          label={t("form.fields.name")}
          name="name"
          errorMessage={getErrorMessage("name")}
        />
        {service && (
          <div className="identifier-input">
            <TextInput
              label={t("form.fields.secondaryIdentifier")}
              name="secondaryIdentifier"
              className=""
              errorMessage={getErrorMessage("secondaryIdentifier")}
              disabled={true}
            />
            <Button
              variant="textOnly"
              iconRight="pi pi-copy"
              size="large"
              type="button"
              onClick={() => copyIdentifier()}
            />
          </div>
        )}
      </div>

      <div className="fields-wrapper">
        <Select
          label={t("form.fields.typeId.label")}
          name="typeId"
          options={Object.entries(SERVICE_TYPES).map(([key, value]) => ({
            label: t(`form.fields.typeId.options.${key}`),
            value: value,
          }))}
          placeholder={t("form.fields.typeId.placeholder")}
        />
        <NumberInput
          label={t("form.fields.port")}
          name="port"
          errorMessage={getErrorMessage("port")}
        />
      </div>

      <Textarea
        label={t("form.fields.connectionConfigs.label")}
        name="connectionConfigs"
        errorMessage={getErrorMessage("connectionConfigs")}
        placeholder={t("form.fields.connectionConfigs.placeholder")}
      />

      <div className="fields-wrapper">
        <TextInput
          label={t("form.fields.image")}
          name="image"
          errorMessage={getErrorMessage("image")}
        />
        <TextInput
          label={t("form.fields.imageVersion")}
          name="imageVersion"
          errorMessage={getErrorMessage("imageVersion")}
        />
      </div>

      <Textarea
        label={t("form.fields.scripts")}
        name="scripts"
        errorMessage={getErrorMessage("scripts")}
      />
      <TextInput
        label={t("form.fields.volumeMounts.label")}
        name="volumeMounts"
        placeholder={t("form.fields.volumeMounts.placeholder")}
        errorMessage={getErrorMessage("volumeMounts")}
      />

      <div className="switch-input-wrapper">
        <div className="checkbox-wrapper">
          <input type="checkbox" {...register("isPublic")} />
          <label htmlFor="isPublic">{t("form.fields.isPublic")}</label>
        </div>

        <div className="checkbox-wrapper">
          <input type="checkbox" {...register("isExternal")} />
          <label htmlFor="isExternal">{t("form.fields.isExternal")}</label>
        </div>
      </div>

      <RegistryFormFields />

      <EnvironmentVariablesInput
        addButtonLabel={t("environmentVariablesSection.buttons.add.label")}
        fields={fields}
        inputName="envVars"
        onAdd={() => append({ name: "", value: "" })}
        onRemove={(index) => remove(index)}
        title={<h3>{t("environmentVariablesSection.title")}</h3>}
      />

      <div className="actions-wrapper">
        <div className="form-actions">
          <Button
            label={t("form.actions.cancel")}
            type="button"
            onClick={handleCancel}
            severity="secondary"
            variant="outlined"
          />
          <SubmitButton
            label={
              !service ? t("form.actions.submit") : t("form.actions.update")
            }
            loading={isLoading}
          />
        </div>
        {service && (
          <Button
            label={t("form.actions.delete")}
            type="button"
            onClick={onDelete}
            severity="danger"
            variant="textOnly"
          />
        )}
      </div>
    </>
  );
};

export default ServiceFormFields;
