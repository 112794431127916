import { State } from "@12degrees/stateful-core";
import {
  Release,
  ReleaseApp,
  ReleaseAppCreateInput,
  ReleaseAppEnginePreset,
  ReleaseAppUpdateInput,
  ReleaseCreateInput,
  ReleaseType,
  ReleaseTypeCreateInput,
  ReleaseTypeUpdateInput,
  ReleaseUpdateInput,
} from "core";

import baseApi, { TagsEnum } from "./base-api";

const releaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    linkReleaseService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/service`,
          method: "POST",
          body: {
            releaseAppId: releaseAppId,
            workspaceServiceId: workspaceServiceId,
          },
        };
      },
    }),
    createRelease: build.mutation<Release, ReleaseCreateInput>({
      query: (data) => {
        return {
          url: "releases",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : [TagsEnum.Workspace]),
    }),

    updateRelease: build.mutation<
      Release,
      { id: number; data: Partial<ReleaseUpdateInput> }
    >({
      query: ({ id, data }) => {
        return {
          url: `releases/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : [TagsEnum.Workspace]),
    }),

    deleteRelease: build.mutation<string, any>({
      query: (id: number) => {
        return {
          url: `/releases/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: string) => response,
      invalidatesTags: [TagsEnum.Release],
    }),
    duplicateRelease: build.mutation<
      Release,
      { releaseId: number; data: ReleaseCreateInput }
    >({
      query: ({ releaseId, data }) => {
        return {
          url: `releases/${releaseId}/duplicate`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Release],
    }),
    getRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    getReleaseStates: build.query<State[], void>({
      query: () => {
        return {
          url: `release/states`,
        };
      },
      providesTags: [TagsEnum.ReleaseStates],
    }),
    getReleaseTypes: build.query<
      ReleaseType[],
      { workspaceId: number; filters?: Record<string, string> }
    >({
      query: (input) => {
        const filterQuery = input.filters
          ? `?filter=${JSON.stringify(input.filters)}`
          : "";

        return {
          url: `workspaces/${input.workspaceId}/release-types${filterQuery}`,
        };
      },
      providesTags: [TagsEnum.ReleaseTypes],
    }),

    getReleaseBySecondaryIdentifier: build.query<
      any,
      { workspaceId: number; releaseSecondaryId: string }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/secondaryId/${input.releaseSecondaryId}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    markReleaseAsReady: build.mutation<
      any,
      { workspaceId: number; releaseId: number }
    >({
      query: ({ workspaceId, releaseId }) => {
        return {
          url: `workspaces/${workspaceId}/releases/${releaseId}/ready`,
          method: "POST",
        };
      },
    }),
    unlinkService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/services/unlink`,
          method: "DELETE",
          body: {
            releaseAppId,
            workspaceServiceId,
          },
        };
      },
    }),
    updateReleaseState: build.mutation<
      { releaseRecord: any },
      { releaseId: number; transitionId: number }
    >({
      query: ({ releaseId, transitionId }) => {
        return {
          url: `/releases/${releaseId}/transitions/${transitionId}`,
          method: "POST",
        };
      },
      invalidatesTags: [TagsEnum.ReleaseStates],
    }),
    deleteReleaseApp: build.mutation<
      void,
      { releaseId: number; appId: number }
    >({
      query: ({ releaseId, appId }) => {
        return {
          url: `releases/${releaseId}/apps/${appId}`,
          method: "DELETE",
        };
      },
    }),
    deployRelease: build.query<any, { workspaceId: number; releaseId: number }>(
      {
        query: (input) => {
          return {
            url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/deploy`,
          };
        },
        providesTags: [TagsEnum.Release],
      },
    ),
    stopRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/stop`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    addReleaseApp: build.mutation<ReleaseApp, ReleaseAppCreateInput>({
      query: (data: ReleaseAppCreateInput) => {
        return {
          url: `releases/${data.releaseId}/apps`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateReleaseApp: build.mutation<
      ReleaseApp,
      { id: number; data: ReleaseAppUpdateInput }
    >({
      query: ({ id, data }) => {
        return {
          url: `releases/${data.releaseId}/apps/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    setupAndBuildRelease: build.query<
      any,
      { workspaceId: number; releaseId: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/prepare`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    createReleaseType: build.mutation<ReleaseType, ReleaseTypeCreateInput>({
      query: (data: ReleaseTypeCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),
    updateReleaseType: build.mutation<
      ReleaseType,
      { id: number; data: ReleaseTypeUpdateInput }
    >({
      query: ({ id, data }) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),
    deleteReleaseType: build.mutation<
      string,
      { workspaceId: number; id: number }
    >({
      query: (data) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types/${data.id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: string) => response,
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),
    getReleaseType: build.query<
      ReleaseType,
      { workspaceId: number; id: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/release-types/${input.id}`,
        };
      },
      providesTags: [TagsEnum.ReleaseTypes],
    }),
    getReleaseAppEnginePresetTemplates: build.query<
      ReleaseAppEnginePreset[],
      void
    >({
      query: () => {
        return {
          url: `/releases/engine-presets`,
        };
      },
      providesTags: ["ReleaseAppEnginePresetTemplates"],
    }),
  }),
});

export const {
  useAddReleaseAppMutation,
  useLinkReleaseServiceMutation,
  useCreateReleaseMutation,
  useUpdateReleaseMutation,
  useDeleteReleaseMutation,
  useDeleteReleaseAppMutation,
  useDuplicateReleaseMutation,
  useMarkReleaseAsReadyMutation,
  useLazyGetReleaseAppEnginePresetTemplatesQuery,
  useLazyGetReleaseQuery,
  useLazyGetReleaseBySecondaryIdentifierQuery,
  useLazyGetReleaseStatesQuery,
  useUpdateReleaseStateMutation,
  useLazyDeployReleaseQuery,
  useLazyStopReleaseQuery,
  useLazySetupAndBuildReleaseQuery,
  useUnlinkServiceMutation,
  useLazyGetReleaseTypesQuery,
  useCreateReleaseTypeMutation,
  useUpdateReleaseTypeMutation,
  useLazyGetReleaseTypeQuery,
  useUpdateReleaseAppMutation,
  useDeleteReleaseTypeMutation,
} = releaseApi;

export default releaseApi;
