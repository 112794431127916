import { useTranslation } from "@dzangolab/react-i18n";
import { Page, SubPane, TabbedPanel } from "@dzangolab/react-ui";

import { SettingsAdvanced, SettingsGeneral } from "./components";

const GeneralSettings = () => {
  const { t } = useTranslation("settings");

  return (
    <Page className="settings-page" title={t("generalSettings.title")}>
      <TabbedPanel>
        <SubPane title={t("generalSettings.tabs.settings")}>
          <SettingsGeneral />
        </SubPane>
        <SubPane title={t("generalSettings.tabs.advanced")}>
          <SettingsAdvanced />
        </SubPane>
      </TabbedPanel>
    </Page>
  );
};

export default GeneralSettings;
