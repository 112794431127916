import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal, Page } from "@dzangolab/react-ui";
import { GlossaryEntry, GlossaryLocales } from "core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GlossaryEntryForm } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useDeleteGlossaryEntryMutation,
  useLazyGetGlossaryEntryQuery,
  useLazyGetGlossaryQuery,
  useLazyGetSupportedLocalesQuery,
} from "../../redux/apis/glossary";

const EditGlossaryEntry = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const { t } = useTranslation("glossary");

  const glossaryId = parseInt(parameters.glossaryId ?? "");
  const entryId = parseInt(parameters.entryId ?? "");

  const [mainLocale, setMainLocale] = useState<GlossaryLocales | undefined>();
  const [glossaryLocales, setGlossaryLocales] = useState<GlossaryLocales[]>([]);
  const [glossaryEntryToDelete, setGlossaryEntryToDelete] =
    useState<GlossaryEntry | null>();

  const [
    fetchGlossaryEntry,
    { data: glossaryEntry, isLoading: isFetchingGlossaryEntry },
  ] = useLazyGetGlossaryEntryQuery();
  const [fetchGlossary, { data: glossary }] = useLazyGetGlossaryQuery();
  const [fetchSupportedLocales, { data: supportedLocales }] =
    useLazyGetSupportedLocalesQuery();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const onFormSubmitSuccess = () => {
    if (currentWorkspace && currentWorkspace.secondaryIdentifier) {
      navigate(`/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`);
    }
  };

  useEffect(() => {
    if (glossaryId && entryId) {
      fetchGlossaryEntry({ glossaryId, entryId });
      fetchGlossary(glossaryId);
      fetchSupportedLocales();
    }
  }, [
    fetchGlossaryEntry,
    glossaryId,
    entryId,
    fetchGlossary,
    fetchSupportedLocales,
  ]);

  useEffect(() => {
    if (glossary) {
      const mainLocale = glossary.locales.find(
        (locale: any) => locale.mainLocale,
      );
      const glossaryLocales = glossary.locales.filter(
        (locale) => !locale.mainLocale,
      );

      if (mainLocale) {
        setMainLocale(mainLocale);
      }

      setGlossaryLocales(glossaryLocales);
    }
  }, [glossary]);

  const getGlossaryTerm = () => {
    const mainLocaleEntry = glossaryEntryToDelete?.i18n.find(
      ({ locale }) => locale === mainLocale?.localeCode,
    );

    if (!mainLocaleEntry) {
      const nonMainLocaleEntry = glossaryEntryToDelete?.i18n.find(
        ({ locale }) => locale !== mainLocale?.localeCode,
      );

      return nonMainLocaleEntry?.term || "";
    }

    return mainLocaleEntry?.term || "";
  };

  const [deleteGlossaryEntry] = useDeleteGlossaryEntryMutation();

  const deleteGlossaryEntryHandler = async () => {
    if (!glossaryEntryToDelete) return;

    let response = await deleteGlossaryEntry({
      entryId: glossaryEntryToDelete.id,
      glossaryId,
    });

    if ("error" in response) {
      toast.error(t("glossary-entry.delete.message.error"));
    } else {
      toast.success(t("glossary-entry.delete.message.success"));
    }
    setGlossaryEntryToDelete(null);
    navigate(`/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`);
  };

  const getEntryDeleteConfirmationModal = () => {
    return (
      <ConfirmationModal
        onHide={() => setGlossaryEntryToDelete(null)}
        key={`delete-confirmation-modal-${glossaryEntryToDelete}`}
        visible={glossaryEntryToDelete != null}
        header={t("glossary-entry.delete.modal.title")}
        message={t("glossary-entry.delete.modal.message", {
          entry: getGlossaryTerm(),
        })}
        acceptButtonOptions={{
          severity: "danger",
          label: t("glossary-entry.delete.modal.actions.delete"),
        }}
        cancelButtonOptions={{
          label: t("glossary-entry.delete.modal.actions.cancel"),
        }}
        accept={() => deleteGlossaryEntryHandler()}
        reject={() => setGlossaryEntryToDelete(null)}
      />
    );
  };

  return (
    <Page
      className="glossary-page"
      title={t("glossary-entry.edit.title")}
      loading={isFetchingGlossaryEntry}
      toolbar={
        glossaryEntry && (
          <Button
            label={t("glossary-entry.form.button.delete")}
            severity="danger"
            type="button"
            variant="filled"
            onClick={() => setGlossaryEntryToDelete(glossaryEntry)}
          />
        )
      }
    >
      {currentWorkspace && glossaryId && mainLocale && supportedLocales && (
        <div className="glossary-edit-page">
          <GlossaryEntryForm
            entry={glossaryEntry}
            glossaryId={glossaryId}
            onFormSubmitSuccess={onFormSubmitSuccess}
            glossaryLocales={glossaryLocales}
            mainLocale={mainLocale}
            supportedLocales={supportedLocales}
          />
          {getEntryDeleteConfirmationModal()}
        </div>
      )}
    </Page>
  );
};

export default EditGlossaryEntry;
