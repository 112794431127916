import { useTranslation } from "@dzangolab/react-i18n";
import { Sidebar, SidebarHeader } from "@dzangolab/react-layout";
import { LoadingPage } from "@dzangolab/react-ui";
import { UserEnabledSidebarOnlyLayout, useUser } from "@dzangolab/react-user";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { NavigationMenu } from "./components";
import { Footer } from "./components/Footer";
import WorkspaceSwitcher from "../components/WorkspaceSwitcher";
import { feature } from "../config";
import { ROUTES } from "../constants";
import { useCurrentWorkspace } from "../hooks/UseCurrentWorkspace";
import { useLazyGetReleaseBySecondaryIdentifierQuery } from "../redux/apis/release";
import { useLazyGetWorkspaceBySecondaryIdentifierQuery } from "../redux/apis/workspaces";
import { setSelectedWorkspace } from "../redux/SelectedWorkspacesSlice";
import { WebSocketProvider } from "../WebSocketContext";

import type { NavGroupType, NavItemType } from "@dzangolab/react-ui";

const getMenuRoutes = () => {
  const releaseRoutes = [
    ...(feature("documentation")
      ? [
          {
            key: "header.menu.docs",
            route: ROUTES.WORKSPACE_DOCS,
            icon: "pi-book",
          },
        ]
      : []),
  ];

  const workspaceRoutes = [
    {
      key: "header.menu.releases",
      route: ROUTES.WORKSPACE_RELEASES,
      icon: "pi-book",
    },
    {
      key: "header.menu.glossary",
      route: ROUTES.WORKSPACE_GLOSSARY,
      icon: "pi-book",
    },
    {
      key: "header.menu.team",
      route: ROUTES.WORKSPACE_TEAM,
      icon: "pi-users",
    },
    {
      key: "header.menu.settings.label",
      route: ROUTES.WORKSPACE_SETTINGS_GENERAL,
      icon: "pi-cog",
      submenu: [
        {
          key: "header.menu.settings.submenu.general",
          route: ROUTES.WORKSPACE_SETTINGS_GENERAL,
        },
        {
          key: "header.menu.settings.submenu.releaseType",
          route: ROUTES.WORKSPACE_RELEASE_TYPE,
        },
        {
          key: "header.menu.settings.submenu.glossary",
          route: ROUTES.WORKSPACE_SETTINGS_GLOSSARY,
        },
        {
          key: "header.menu.repositories",
          route: ROUTES.WORKSPACE_REPOSITORIES,
        },
        {
          key: "header.menu.services",
          route: ROUTES.SERVICES,
        },
        ...(feature("documentation")
          ? [
              {
                key: "header.menu.settings.submenu.docs",
                route: ROUTES.WORKSPACE_SETTINGS_DOCUMENTATION,
              },
            ]
          : []),
      ],
    },
  ];

  const menuRoutes = {
    release: releaseRoutes,
    workspace: workspaceRoutes,
  };

  return menuRoutes;
};

export const WorkspaceLayout = (): JSX.Element => {
  const { t } = useTranslation("app");
  const parameters = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();

  const { workspaceSecondaryId, releaseSecondaryId } = parameters as {
    workspaceSecondaryId: string;
    releaseSecondaryId: string;
  };

  const [
    getWorkspaceBySecondaryIdentifier,
    { data: workspace, isFetching: isFetchingWorkspace, error: workspaceError },
  ] = useLazyGetWorkspaceBySecondaryIdentifierQuery();
  const [
    getReleaseBySecondaryIdentifier,
    { data: release, isFetching: isFetchingRelease },
  ] = useLazyGetReleaseBySecondaryIdentifierQuery();

  const { workspace: selectedWorkspace, release: selectedRelease } =
    useCurrentWorkspace();

  const getNavigationMenu = () => {
    const menuRoutes = getMenuRoutes();
    const releaseRoutes = menuRoutes.release;
    const workspaceRoutes = menuRoutes.workspace;

    const releaseSpecificMenu = generateNavigationMenu(
      t,
      releaseRoutes,
      selectedWorkspace?.secondaryIdentifier,
      selectedRelease?.secondaryIdentifier,
    );

    const workspaceMenu = generateNavigationMenu(
      t,
      workspaceRoutes,
      selectedWorkspace?.secondaryIdentifier,
      selectedRelease?.secondaryIdentifier,
    );

    let menu = [{ id: "workspace-navigation-menu", menu: workspaceMenu }];

    if (selectedRelease) {
      menu.unshift({
        id: "release-navigation-menu",
        menu: releaseSpecificMenu,
      });
    }

    return menu;
  };

  useEffect(() => {
    if (workspaceSecondaryId) {
      getWorkspaceBySecondaryIdentifier(workspaceSecondaryId);
    }
  }, [workspaceSecondaryId, getWorkspaceBySecondaryIdentifier]);

  useEffect(() => {
    if (
      !isFetchingWorkspace &&
      workspaceSecondaryId &&
      workspaceError &&
      "status" in workspaceError &&
      workspaceError.status === 404
    ) {
      navigate(ROUTES.WORKSPACES);
    }
  }, [workspaceSecondaryId, isFetchingWorkspace, workspaceError, navigate]);

  useEffect(() => {
    if (workspace && workspace.id && releaseSecondaryId) {
      getReleaseBySecondaryIdentifier({
        workspaceId: workspace.id,
        releaseSecondaryId,
      });
    }
  }, [workspace, releaseSecondaryId, getReleaseBySecondaryIdentifier]);

  useEffect(() => {
    if (workspace) {
      dispatch(setSelectedWorkspace({ workspace, release }));
    }
  }, [workspace, release, dispatch]);

  const navigationMenu = getNavigationMenu();

  const getCustomSidebar = () => {
    return (
      <Sidebar>
        <SidebarHeader />
        <NavigationMenu navigationMenu={user ? navigationMenu : []} />
      </Sidebar>
    );
  };

  return (
    <WebSocketProvider>
      <UserEnabledSidebarOnlyLayout
        className="workspace-layout"
        noLocaleSwitcher={false}
        children={
          isFetchingRelease || isFetchingWorkspace ? (
            <LoadingPage />
          ) : (
            <Outlet />
          )
        }
        customSidebar={getCustomSidebar()}
        displayNavIcons={true}
      />
      <Footer>
        <WorkspaceSwitcher />
      </Footer>
    </WebSocketProvider>
  );
};

const generateNavigationMenu = (
  t: (key: string) => string,
  menuRoutes: {
    key: string;
    icon: string;
    route: string;
    submenu?: { key: string; route: string }[];
  }[],
  workspaceSecondaryId: string,
  releaseSecondaryId: string,
): Array<NavItemType | NavGroupType> => {
  const routes: Array<NavItemType | NavGroupType> = [];

  menuRoutes.forEach((route) => {
    let submenu: Array<NavItemType> = [];

    if (route.submenu) {
      route.submenu.forEach((subroute) => {
        submenu.push({
          ...subroute,
          route: subroute.route
            .replace(":workspaceSecondaryId", workspaceSecondaryId)
            .replace(":releaseSecondaryId", releaseSecondaryId),
          label: t(subroute.key),
        });
      });
    }

    routes.push({
      ...route,
      ...(submenu.length > 0 && { submenu }),
      route: route.route
        .replace(":workspaceSecondaryId", workspaceSecondaryId)
        .replace(":releaseSecondaryId", releaseSecondaryId),
      label: t(route.key),
      icon: `pi ${route.icon}`,
    });
  });

  return routes;
};
