import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { Release, ReleaseCreateInput, ReleaseType } from "core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import { useCreateReleaseMutation } from "../../../../redux/apis/release";
import ReleaseForm from "../ReleaseForm";

interface Properties {
  onHide: () => void;
  releaseTypes?: ReleaseType[];
  visible: boolean;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface SuccessResponse {
  data: Release;
}

const ReleaseFormModal: React.FC<Properties> = ({
  onHide,
  releaseTypes,
  visible,
}) => {
  const { t } = useTranslation("release");
  const navigate = useNavigate();

  const { workspace } = useCurrentWorkspace();
  const [triggerAdd, { isLoading: isCreating }] = useCreateReleaseMutation();

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>();

  const closeModal = () => {
    setFieldErrors({});
    onHide();
  };

  const handleSubmit = async (data: ReleaseCreateInput) => {
    if (!workspace) return;

    let response: SuccessResponse | ErrorResponse;

    try {
      response = (await triggerAdd({
        ...data,
        workspaceId: workspace.id,
      })) as SuccessResponse | ErrorResponse;

      if ("data" in response) {
        const successMessage = t("messages.success.create");

        toast.success(successMessage);

        navigate(
          `/workspaces/${workspace.secondaryIdentifier}/releases/${response.data.secondaryIdentifier}`,
        );

        closeModal();
      } else if ("error" in response) {
        handleErrorResponse(response.error);
      }
    } catch {
      toast.error(t("messages.error.default"));
    }
  };

  const handleErrorResponse = (error: ErrorResponse["error"]) => {
    const errorMessage = t("messages.error.create");

    toast.error(errorMessage);
  };

  return (
    <Modal
      className="release-form-modal"
      header={t("release-form-modal.header.new")}
      onHide={closeModal}
      visible={visible}
    >
      <ReleaseForm
        fieldErrors={fieldErrors}
        onCancel={closeModal}
        onSubmit={handleSubmit}
        isSubmitting={isCreating}
        releaseTypes={releaseTypes}
      />
    </Modal>
  );
};

export default ReleaseFormModal;
